import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";
import { CookieService } from "src/app/shared/services/cookie.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  providers: [DataService],
})
export class FooterComponent extends BaseComponent {
  footerData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    private cookieService: CookieService
  ) {
    super(meta, title, router, dataService, null);
  }

  ngOnInit(): void {
    this.dataService.loadFooter();
    this.dataService.listData.subscribe((data: any[]) => {
      this.footerData = data;
    });
  }

  showCookieConsentDialog(event) {
    this.cookieService.showCookieConsentDialog();
    event.stopPropagation();
  }
}
