import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class StatisticsService {
  initStats: Observable<any>;
  results: Observable<any>;
  searhing: Observable<boolean>;

  private _initStats: BehaviorSubject<any>;
  private _results: BehaviorSubject<any>;
  private _searching: BehaviorSubject<boolean>;

  private dataStore: {
    initStats: any;
    results: any;
  };

  constructor(private httpClient: HttpClient) {
    this.dataStore = { initStats: null, results: null };

    this._initStats = <BehaviorSubject<any>>new BehaviorSubject(null);
    this.initStats = this._initStats.asObservable();

    this._results = <BehaviorSubject<any>>new BehaviorSubject(null);
    this.results = this._results.asObservable();

    this._searching = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.searhing = this._searching.asObservable();
  }

  loadInitStats() {
    this.setInitStats(null);

    this.httpClient
      .get<any>(environment.umbracoEndpoint + "/api/stats/initstats")
      .subscribe(
        (data: any) => {
          this.setInitStats(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  searchWithFilter(filter: StatisticsSearchFilterModel) {
    this._searching.next(true);
    this.setResults(null);

    this.httpClient
      .post<any[]>(
        environment.umbracoEndpoint + "/api/stats/getstatistics",
        filter
      )
      .subscribe(
        (data: any[]) => {
          this.setResults(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {
          this._searching.next(false);
        }
      );
  }

  staticSearch() {
    this.httpClient
      .get<any[]>(environment.umbracoEndpoint + "/api/stats/getstatistics")
      .subscribe((data: any[]) => {
        this.setResults(data);
      });
  }

  private setInitStats(data: any) {
    this.dataStore.initStats = data;
    this._initStats.next(Object.assign({}, this.dataStore).initStats);
  }

  private setResults(data: any) {
    this.dataStore.results = data;
    this._results.next(Object.assign({}, this.dataStore).results);
  }
}

export interface StatisticsSearchFilterModel {
  Month: number;
  Year: number;
}
