import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import fileSaver from "file-saver";

@Injectable({ providedIn: "root" })
export class ComplaintsService {
  searhing: Observable<boolean>;
  config: Observable<any>;
  results: Observable<any>;
  processing: Observable<any>;
  complaint: Observable<ArkivsakModel>;
  complaintHtml: Observable<ArkivsakModelHtml>;

  private _complaint: BehaviorSubject<ArkivsakModel>;
  private _complaintHtml: BehaviorSubject<ArkivsakModelHtml>;
  private _searching: BehaviorSubject<boolean>;
  private _results: BehaviorSubject<any[]>;
  private _processing: BehaviorSubject<any>;
  private _config: BehaviorSubject<any>;

  private dataStore: {
    complaint: ArkivsakModel;
    complaintHtml: ArkivsakModelHtml;
    results: any[];
    config: any;
  };

  constructor(private httpClient: HttpClient) {
    this.dataStore = {
      complaint: null,
      complaintHtml: null,
      results: null,
      config: null,
    };

    this._complaint = <BehaviorSubject<ArkivsakModel>>new BehaviorSubject(null);
    this.complaint = this._complaint.asObservable();

    this._complaintHtml = <BehaviorSubject<ArkivsakModelHtml>>(
      new BehaviorSubject(null)
    );
    this.complaintHtml = this._complaintHtml.asObservable();

    this._searching = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.searhing = this._searching.asObservable();

    this._results = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.results = this._results.asObservable();

    this._processing = <BehaviorSubject<any>>new BehaviorSubject(null);
    this.processing = this._processing.asObservable();

    this._config = <BehaviorSubject<any>>new BehaviorSubject(null);
    this.config = this._config.asObservable();
  }

  loadConfig() {
    this.setConfig(null);

    this.httpClient
      .get<any>(environment.umbracoEndpoint + "/api/stats/config")
      .subscribe((data: any) => {
        this.setConfig(data);
      });
  }

  loadComplaint(arkivSakId) {
    // umbraco/api/stats/details/2013000009
    this.setComplaint(null);

    this.httpClient
      .get<ArkivsakModel>(
        environment.umbracoEndpoint + "/api/stats/details/" + arkivSakId
      )
      .subscribe(
        (data: ArkivsakModel) => {
          this.setComplaint(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  loadComplaintHtml(arkivSakId, file: ArkivsakFile) {
    this.httpClient
      .post<any>(
        environment.umbracoEndpoint + "/api/stats/html/" + arkivSakId,
        file
      )
      .subscribe(
        (data: any) => {
          this.setComplaintHtml(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  loadProcessingTime() {
    this.httpClient
      .get<any>(environment.umbracoEndpoint + "/api/stats/ProceedingTime")
      .subscribe(
        (data: any) => {
          this._processing.next(data);
        },
        (error: HttpErrorResponse) => {
          console.warn(error);
        },
        () => {}
      );
  }

  search(filter: SearchFilterModel) {
    this._searching.next(true);
    this.setResults(null);

    this.httpClient
      .post<any[]>(environment.umbracoEndpoint + "/api/case/search", filter)
      .subscribe(
        (data: any[]) => {
          this.setResults(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {
          this._searching.next(false);
        }
      );
  }

  exportToExcel(results: any[]) {
    this.httpClient
      .post(environment.umbracoEndpoint + "/api/export/exportdata", results, {
        responseType: "arraybuffer",
      })
      .subscribe(
        (data: ArrayBuffer) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fileSaver.saveAs(blob, "Klagesaker.xlsx");
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
  }

  private setConfig(data: any) {
    this.dataStore.config = data;
    this._config.next(Object.assign({}, this.dataStore).config);
  }

  private setResults(data: any) {
    this.dataStore.results = data;
    this._results.next(Object.assign({}, this.dataStore).results);
  }

  private setComplaint(data: ArkivsakModel) {
    this.dataStore.complaint = data;
    this._complaint.next(Object.assign({}, this.dataStore).complaint);
  }

  private setComplaintHtml(data: ArkivsakModelHtml) {
    this.dataStore.complaintHtml = data;
    this._complaintHtml.next(Object.assign({}, this.dataStore).complaintHtml);
  }
}

export interface SearchFilterModel {
  SearchFilters: SearchFilter[];
  SearchTerm: string;
}

export interface SearchFilter {
  ColId: number;
  SearchFilter: string;
}

export interface ArkivsakModel {
  arkivsakId: number;
  arkivsakIdFriendlyName: string;
  dato: Date;
  arstall: number;
  datoManed: number;
  aarMottatt: number;
  aarAvsluttet: number;
  klageMottatt: Date;
  status: string;
  spesialgrunnlag: string;
  samfunnsomrade: string;
  kjonn: string;
  datoAvsluttet: Date;
  saksomtale: string;
  pdfFile: string;
  pdfFiler: ArkivsakFile[];
  resultat: string[];
  grunnlag: string[];
  bruddmate: string[];
  spesielleForhold: string[];
  konsekvensBrudd: string[];
  avvist: string[];
  henlagt: string[];
}

export interface ArkivsakFile {
  url: string;
  name: string;
}

export interface ArkivsakModelHtml {
  ArkivSak: ArkivsakModel;
  FileInfo: ArkivsakFile;
  HTML: string;
}
