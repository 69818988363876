import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  CookieConsent,
  CookieConsentItem,
} from "src/app/models/cookie.consent.model";
import { LazyLoadingScriptService } from "./lazy-loading-script.service";

@Injectable()
export class CookieService {
  private _consentVisible: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public consentVisible: Observable<boolean> =
    this._consentVisible.asObservable();

  private _cookie: Subject<CookieConsent> = new Subject();
  public cookie: Observable<CookieConsent> = this._cookie.asObservable();

  constructor(private lazyLoadService: LazyLoadingScriptService) {}

  public loadCookie() {
    this._cookie.next(this.getCookie());
  }

  public showCookieConsentDialog(): void {
    this._consentVisible.next(true);
  }

  public hideCookieConsentDialog(): void {
    this._consentVisible.next(false);
  }

  public saveCookieConsent(value: CookieConsent): void {
    this.clearCookies();
    this.setCookie("consent", value, 90);

    this.loadScripts();
  }

  private getCookie(): CookieConsent {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = "consent="; //`${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        let cookieStr = c.substring(cookieName.length, c.length);
        return JSON.parse(cookieStr);
      }
    }

    return undefined;
  }

  private clearCookies() {
    let domain: string = window.location.hostname;
    domain =
      "." +
      domain.substring(
        domain.lastIndexOf(".", domain.lastIndexOf(".") - 1) + 1
      );

    let cookies: Array<string> = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie =
        name +
        "=;path=/;domain=" +
        domain +
        ";expires=Thu, 01 Jan 1970 00:00:00 UTC";
    }
  }

  private setCookie(
    name: string,
    value: any,
    expireDays: number,
    path: string = ""
  ) {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : "";

    let domain: string = window.location.hostname;
    domain =
      "; domain=." +
      domain.substring(
        domain.lastIndexOf(".", domain.lastIndexOf(".") - 1) + 1
      );
    document.cookie = `${name}=${JSON.stringify(
      value
    )}; ${expires}${cpath}${domain}`;

    this._cookie.next(this.getCookie());
  }

  public loadScripts() {
    let cookie: CookieConsent | undefined = this.getCookie();

    if (cookie !== undefined) {
      Object.keys(cookie).forEach((key: string) => {
        if (key.toLowerCase() === "google") {
          if (cookie[key] === true) {
            window["ga-disable-UA-165948252-1"] = false;

            this.lazyLoadService
              .loadScript(
                "https://www.googletagmanager.com/gtag/js?id=UA-165948252-1"
              )
              .subscribe(() => {
                // console.log('loaded Google Tag manager');

                this.lazyLoadService
                  .loadScript("assets/script/google/ga.js")
                  .subscribe(() => {
                    // console.log("loaded Google analytics tracking script");
                  });
              });
          } else {
            window["ga-disable-UA-165948252-1"] = true;
            // if (!value.google) {
            //     this.setCookie('ga-disable-UA-165948252-1', true, 90);
            // }
          }
        }

        if (key.toLowerCase() === "facebook") {
          if (cookie[key] === true) {
            window["fb-pixel-379794683274276-disable"] = false;

            this.lazyLoadService
              .loadScript("assets/script/facebook/pixel.js")
              .subscribe(() => {
                // console.log("loaded Facebook pixel script");
              });
            this.lazyLoadService
              .loadScript("assets/script/delta/pageview.js")
              .subscribe(() => {
                // console.log("loaded Delta Projects pageview script");
              });
          } else {
            window["fb-pixel-379794683274276-disable"] = true;
          }
        }
      });
    }
  }
}
