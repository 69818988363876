import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadCrumbService } from '../../services/bread-crumb.service';

@Component({
  selector: 'app-advenio-breadcrumbs',
  templateUrl: './advenio-breadcrumbs.component.html',
})
export class AdvenioBreadcrumbsComponent implements OnInit, OnDestroy {

  private componentDestroyed = new Subject();

  breadcrumbData: AdvenioCrumb[];

  constructor(private breadcrumbService: BreadCrumbService) {

    this.breadcrumbService.breadCrumbData
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (data: AdvenioCrumb[]) => {
          this.breadcrumbData = data;
        }
      );


  }
  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() { }

}

export interface AdvenioCrumb {
  title: string;
  url: string;
}
