import { DataService } from "src/app/shared/services/data.service";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { BaseComponent } from "../base/base.component";
import { BreadCrumbService } from "../../services/bread-crumb.service";
import { Meta, Title } from "@angular/platform-browser";

export enum SortOrder {
  None = 0,
  Desc = 1,
  Asc = 2,
}

@Component({
  selector: "app-newsall",
  templateUrl: "./newsall.component.html",
  providers: [DataService],
})
export class NewsAllComponent extends BaseComponent {
  sortOrder: SortOrder = SortOrder.None;
  sortField: number = -1;
  rowData: any[];
  unsortedData: any[];

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
  ) {
    super(meta, title, router, dataService, breadcrumbService);
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  ngOnInit() {
    this.dataService.loadNewsAll();
    this.dataService.listData.subscribe((data: any[]) => {
      this.rowData = data;
    });
  }

  setSort(sort: number) {
    if (sort === this.sortField) {
      //switch sortorder
      if (this.sortOrder === SortOrder.Asc) {
        this.sortOrder = SortOrder.Desc;
      } else if (this.sortOrder === SortOrder.Desc) {
        this.sortOrder = SortOrder.None;
      } else if (this.sortOrder === SortOrder.None) {
        this.sortOrder = SortOrder.Asc;
      }
    } else {
      this.sortField = sort;
      this.sortOrder = SortOrder.Asc;
    }

    if (this.sortOrder === SortOrder.None) {
      this.rowData = this.unsortedData.slice();
    } else {
      this.rowData = this.rowData.sort((a, b) => {
        return this.compare(a, b);
      });
    }
  }

  compare(a: any, b: any) {
    if (this.sortField === 0) {
      if (a.Name < b.Name) {
        return this.sortOrder === SortOrder.Asc ? -1 : 1;
      }
      if (a.Name > b.Name) {
        return this.sortOrder === SortOrder.Asc ? 1 : -1;
      }
    } else if (this.sortField === 1) {
      if (a.UpdateDate < b.UpdateDate) {
        return this.sortOrder === SortOrder.Asc ? -1 : 1;
      }
      if (a.UpdateDate > b.UpdateDate) {
        return this.sortOrder === SortOrder.Asc ? 1 : -1;
      }
    } else if (this.sortField === 2) {
      if (a.publiseringsdato < b.publiseringsdato) {
        return this.sortOrder === SortOrder.Asc ? -1 : 1;
      }
      if (a.publiseringsdato > b.publiseringsdato) {
        return this.sortOrder === SortOrder.Asc ? 1 : -1;
      }
    }
    return 0;
  }

  goToArticle(path: string) {
    this.router.navigate(["/nyheter/" + path]);
  }
}
