import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-complaints-and-statistics-container.component',
  templateUrl: './complaints-and-statistics-container.component.html',
})
export class ComplaintsAndStatisticsContainerComponent extends BaseComponent implements OnInit {

  ngOnInit(): void {

  }
  

}
