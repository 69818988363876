import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-complaint-reasons',
  templateUrl: './complaint-reasons.component.html',
  styles: [],
  providers: [DataService]
})
export class ComplaintReasonsComponent extends BaseComponent {

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute) {
    super(meta, title, router, dataService, breadcrumbService);

  }

  ngOnInit(): void {
      this.breadcrumbService.refresh('Klagegrunnlag');
  }
  
}

