import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Observable } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-annual-reports",
  templateUrl: "./annual-reports.component.html",
  styles: [],
  providers: [DataService],
})
export class AnnualReportsComponent extends BaseComponent {
  annualReportsInfoData: any;
  annualReportsData: any;

  ngOnInit() {
    this.dataService.loadAnnualreports();
    this.dataService.listData.subscribe((data: any[]) => {
      this.annualReportsData = data;
    });
  }
}
