
import {Pipe, PipeTransform} from '@angular/core';

@Pipe ({
   name : 'highlight'
})

export class HighLightPipe implements PipeTransform {
   transform(html: string, searchTerm: string): string {

    if (!searchTerm){
        return html;
    }

    html = html.replace(searchTerm, '<span class="highlight-text">' + searchTerm + '</span>');

    return html;

   }
}