import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Meta, Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { SearchService } from "src/app/shared/services/search.service";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styles: [],
  providers: [DataService],
})
export class SearchComponent extends BaseComponent implements OnInit {
  year: number;
  searchResult$: Observable<any>;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private searchService: SearchService
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    if (!this.searchService.searchTerm) {
      this.router.navigateByUrl("/");
    }

    this.searchResult$ = this.searchService.searchResult;

    this.searchService.configResult
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((year: number) => {
        this.year = year;
        this.searchService.search();
      });

    this.searchService.getYear();
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  //THis is probably not in use anymore, replaced by returnUrl
  openSearchResult(item) {
    if (item.DocumentTypeAlias.toLowerCase() === "vedtak") {
      var url = "";
      if (item.Properties && item.Properties.vedtaksdato) {
        var date = new Date(item.Properties.vedtaksdato);
        if (date.getFullYear() <= this.year) {
          url = "/showoldcase/" + item.Id;
        } else {
          //saksnummer
          var caseNum = this.parseCaseNum(item.Properties.saksnummer);
          url = "/showcase/" + caseNum;
        }
      }

      //let url = '/showoldcase/' + item.Id;
      window.open(url, "_blank");
    } else {
      window.location.href = item.Url;
    }
  }

  returnUrl(item) {
    // Is News articles
    if (item.ContentType.toLowerCase() === "artikkel")
      return `/nyheter/${item.Id}`;
    // Text items in privacy policy
    if (item.ParentName.toLowerCase() === "personvernerklæring")
      return `/erklæringer/Personvernerklæring`;
    // Cookie Policy
    if (item.ParentName.toLowerCase() === "erklæringer")
      return `/erklæringer/Oversikt%20over%20informasjonskapsler`;

    if (item.DocumentTypeAlias.toLowerCase() === "vedtak") {
      var url = "";
      if (item.Properties && item.Properties.vedtaksdato) {
        var date = new Date(item.Properties.vedtaksdato);
        if (date.getFullYear() <= this.year) {
          url = "/showoldcase/" + item.Id;
        } else {
          //saksnummer
          var caseNum = this.parseCaseNum(item.Properties.saksnummer);
          url = "/showcase/" + caseNum;
        }
      }

      //let url = '/showoldcase/' + item.Id;
      return url;
    } else {
      return item.Url;
    }
  }

  parseCaseNum(value: string) {
    //input val = 19/65
    var caseNum = "";
    var elements = value.split("/");
    if (elements.length === 2) {
      var year = elements[0];
      var num = elements[1];

      if (year.length === 2) {
        year = "20" + year;
      }

      num = num.replace(/\d+/g, function (m) {
        return "00000".substr(m.length - 1) + m;
      });

      caseNum = year + num;
    }

    return caseNum;
  }
}
