import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-campaign-video',
  templateUrl: './campaign-video.component.html',
})
export class CampaignVideoComponent implements OnInit, OnDestroy {

  private componentDestroyed = new Subject();

  @Input() videoSource: string = '';

  constructor(private sanitizer: DomSanitizer, private cookieService: CookieService) {

  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() {

  }

  videoURL(): SafeResourceUrl {

    let url = '';

    if (this.videoSource) {
      url = this.videoSource + '?title=0&byline=0&portrait=0&dnt=1';
      return this.sanitizer.bypassSecurityTrustResourceUrl(url); //.bypassSecurityTrustUrl(url);
    }

    return '';

  }

}
