import { Injectable, ChangeDetectorRef, NgZone } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

interface vFact_HTML5Player {
    iFrameID: string;
    setEventHandler_OnChangePlaylistStatus(event): any;

}

type vFact_playsection = (id: string) => any;
type vFact_playsection_or_selection = (id: string) => any;
type vFact_doplay = () => any;
type vFact_dostop = () => any;
type vFact_dopause = () => any;

declare var vFact_doplay: vFact_doplay;
declare var vFact_dostop: vFact_dostop;
declare var vFact_dopause: vFact_dopause;
declare var vFact_playsection: vFact_playsection;
declare var vFact_playsection_or_selection: vFact_playsection_or_selection;
declare var vFact_HTML5Player: vFact_HTML5Player;

@Injectable()
export class VFactService {

    public isReading: Observable<boolean>;
    private _isReading: BehaviorSubject<boolean>;

    private eventInit = false;

    constructor(private _ngZone: NgZone) {
        this._isReading = new BehaviorSubject(false);
        this.isReading = this._isReading.asObservable();
    }

    isInitialized() {

        if (typeof vFact_HTML5Player !== 'undefined') { // !== undefined) {
            const elem = document.getElementById(vFact_HTML5Player.iFrameID);
            return elem !== null;
        }
        return false;
    }

    playSection(id: string) {
        if (this.ensureInit()) {
            vFact_playsection_or_selection(id);
            // vFact_doplay();
        }
    }
    stop() {
        if (this.ensureInit()) {
            vFact_dostop();
        }
    }

    pause() {
        if (this.ensureInit()) {
            vFact_dopause();
        }
    }


    private ensureInit(): boolean {

        let init = false;
        if (this.isInitialized()) {
            if (!this.eventInit) {
                vFact_HTML5Player.setEventHandler_OnChangePlaylistStatus(
                    (newPlaylistStatus) => {

                        this._ngZone.run(() => {
                            if (newPlaylistStatus === 0) {
                                this._isReading.next(false);
                            } else {
                                this._isReading.next(true);
                            }
                        });
                    }
                );
                this.eventInit = true;
            }
            init = true;
        }

        return init;
    }


}
