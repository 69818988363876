import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { UmbracoService } from "src/app/shared/services/umbraco.service";

import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SearchService {
  public configResult: Observable<any>;
  private _configResult: BehaviorSubject<any>;

  public searchResult: Observable<any>;
  public searchTerm: string;

  private _searchResult: BehaviorSubject<any>;
  private dataStore: {
    searchResult: any;
  };

  constructor(
    private umbracoService: UmbracoService,
    private httpClient: HttpClient
  ) {
    this.dataStore = { searchResult: null };

    this._searchResult = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.searchResult = this._searchResult.asObservable();

    this._configResult = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.configResult = this._configResult.asObservable();
  }

  getYear() {
    this.httpClient
      .get(environment.umbracoEndpoint + "/api/content/GetYear")
      .subscribe((data: any) => {
        this._configResult.next(data);
      });
  }

  search() {
    if (this.searchTerm) {
      const query = { SearchTerm: this.searchTerm };

      this.httpClient
        .post(environment.umbracoEndpoint + "/api/content/GlobalSearch", query)
        .subscribe((data: any) => {
          this.setResults(data);
        });
    }
  }

  private setResults(data: any) {
    this.dataStore.searchResult = data;
    this._searchResult.next(Object.assign({}, this.dataStore).searchResult);

    // console.log(this.dataStore.searchResult);

    this.searchTerm = "";
  }
}
