import { Meta, Title } from '@angular/platform-browser';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DataService } from 'src/app/shared/services/data.service';
import { UntypedFormGroup, Validators, FormControlDirective, FormControlName, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { SubscriptionService, SubscriptionType, SubscriptionInput } from 'src/app/shared/services/subscription.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';


const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);

  if (this.control) {
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  }

  return result;
};

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  providers: [DataService]
})
export class SubscriptionComponent extends BaseComponent {

  subscriptionTypes: SubscriptionType[];
  subscriptionForm: UntypedFormGroup;
  submitting: boolean;
  showMessage = false;

  subscriptionId: string;
  showUnsubscribeMessage: boolean;
  unsubscribeMessage: string;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder) {
    super(meta, title, router, dataService, breadcrumbService);


    this.route.params
      .subscribe(
        (params: Params) => {
          if (params.id) {
            this.subscriptionId = params.id;
            this.unsubscribeMessage = '';
          } else {
            this.setupSubscriptions();
          }
        }
      );
  }

  setupSubscriptions() {
    this.subscriptionService.subscriptionTypes
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (types: SubscriptionType[]) => {
          if (types !== undefined) {
            this.subscriptionTypes = types;
            this.createForm();
          }
        }
      );
    this.subscriptionService.loadSubscriptionTypes();
  }

  createForm() {

    this.subscriptionForm = this.fb.group({
      // FirstName: ['', Validators.required],
      // LastName: ['', Validators.required],
      Email: ['', [Validators.required, ValidationService.emailValidator]],
      // Phone: [''],
      Company: [''],
      SubscriptionTypes: new UntypedFormGroup(this.buildSubscriptionTypes(), ValidationService.requireCheckboxesToBeCheckedValidator)
      // this.buildSubscriptionTypes(), ValidationService.requireCheckboxesToBeCheckedValidator)
    });
  }

  postSubscription() {

    if (!this.subscriptionForm.valid) {
      this.checkControls(this.subscriptionForm);
      return;
    }

    this.submitting = true;

    const types: SubscriptionType[] = [];

    Object.keys((this.subscriptionForm.controls.SubscriptionTypes as UntypedFormGroup).controls)
      .forEach((key: string, index: number) => {
        const control = (this.subscriptionForm.controls.SubscriptionTypes as UntypedFormGroup).controls[key];
        if (control.value === true) {
          types.push(this.subscriptionTypes[index]);
        }
      });

    const inputData: SubscriptionInput = {
      Subscriber: {
        // FirstName: this.subscriptionForm.controls.FirstName.value,
        // LastName: this.subscriptionForm.controls.LastName.value,
        Email: this.subscriptionForm.controls.Email.value,
        // Phone: this.subscriptionForm.controls.Phone.value,
        Company: this.subscriptionForm.controls.Company.value,
      },
      SubscriptionTypes: types
    };

    this.subscriptionService.saveSubscriptions(inputData)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (data: SubscriptionType[]) => {
          console.log(data);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        },
        () => {
          this.submitting = false;
          this.showMessage = true;
        }
      );

  }

  buildSubscriptionTypes() {

    const formControls = {};
    this.subscriptionTypes
      .forEach(
        (type: SubscriptionType, index: number) => {
          formControls['subscriptionTypeCheck_' + type.Id] =
            new UntypedFormControl(
              { value: (index === 0 ? true : false), disabled: (index > 0 ? true : false) }
            );
        });
    return formControls;
  }

  checkControls(group: UntypedFormGroup) {

    // tslint:disable-next-line: forin
    for (const fieldName in group.controls) { // 'field' is a string
      const control = group.get(fieldName); // 'control' is a FormControl

      if (control instanceof UntypedFormGroup) {
        if (this.checkControls(control)) {
          break;
        }
      } else {
        if (control.invalid && (!control.dirty)) {
          control.markAsDirty();
          if (control) {
            (<any>control).nativeElement.focus();
          }
          return true;
        }
      }
    }
  }

  unsubscribe() {

    this.subscriptionService.unsubscribe(this.subscriptionId)
      .subscribe(
        (val: boolean) => {
          this.unsubscribeMessage = 'Takk - Du er nå avmeldt abonnement på avgjorte saker!';
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.unsubscribeMessage = err.message;
        },
        () => {
          console.log('Unsubscribe finished');
          this.showUnsubscribeMessage = true;
        }
      );


  }


}
