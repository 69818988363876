import { Component, Input } from "@angular/core";
import { DataService } from "../../services/data.service";
import { DarkModeService } from "../../services/dark-mode.service";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../base/base.component";
import { BreadCrumbService } from "../../services/bread-crumb.service";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-box-menu",
  templateUrl: "./box-menu.component.html",
  providers: [DataService],
})
export class BoxMenuComponent extends BaseComponent {
  menuData: any;
  @Input() nodeName: string;
  @Input() menuId: any;

  darkMode: boolean = false;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    private darkModeService: DarkModeService
  ) {
    super(meta, title, router, dataService, null);

    this.darkModeService.darkMode
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((val: boolean) => {
        this.darkMode = val;
      });
  }

  ngOnInit(): void {
    this.dataService.loadMenuBoxes(this.menuId);
    this.dataService.listData.subscribe((data: any[]) => {
      this.menuData = data;
    });
  }
}
