import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Data, Params, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RelatedLinkModel } from "src/app/models/related-link.model";
import { BreadCrumbService } from "../../services/bread-crumb.service";
import { DataService } from "../../services/data.service";
import { LanguageComponent } from "../base/language.component";

@Component({
  selector: "app-language-page",
  templateUrl: "./language-page.component.html",
  styles: [],
})
export class LanguagePageComponent extends LanguageComponent implements OnInit {
  protected nodeType: string = "basisSide";
  protected id: string;
  protected twoColumns: boolean = false;
  protected showProcessingTime: boolean = false;

  protected extraParagraphs: any[];
  protected explainingFiles: any[];

  languagepageData: any;

  @Input() templateRef: TemplateRef<any>;
  @Input() templateRefTop: TemplateRef<any>;
  @Input() imageTemplateRef: TemplateRef<any>;
  @Input() templateRefExtraText: TemplateRef<any>;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute
  ) {
    super(meta, title, router, dataService, breadcrumbService);
  }

  ngOnInit(): void {
    this.dataService.data.subscribe((data: any) => {
      // console.log("datasfsdfsdf", data);
      // if (data != null) {
      //   if (data !== undefined) {
      //     this.extraParagraphs = data.filter(
      //       (x) => x.contentType === "tekstdata"
      //     );
      //     this.explainingFiles = data.filter(
      //       (x) => x.contentType === "forklarendeFil"
      //     );
      //     if (this.openFirstParagraph && this.extraParagraphs.length > 0) {
      //       this.extraParagraphs[0].showParagraph = true;
      //     }
      //   }
      // }
    });

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        if (params.id) {
          let id = params.id;
          if (this.id !== id) {
            this.id = params.id;
          }
        }
      });

    this.route.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((d: Data) => {
        let id = d.id;
        if (this.id === undefined && id !== undefined) {
          this.id = id;
        }

        let nodeType = d["nodeType"];
        if (nodeType !== undefined) {
          this.nodeType = nodeType;
        }

        let twoColumns = d["twoColumns"] as boolean;
        if (twoColumns !== undefined) {
          this.twoColumns = twoColumns;
        }

        let showProcessingTime = d["showProcessingTime"] as boolean;
        if (showProcessingTime !== undefined) {
          this.showProcessingTime = showProcessingTime;
        }

        if (this.id !== undefined) {
          this.dataService.loadNodeById(this.id);
          this.dataService.listData.subscribe((data: any[]) => {
            this.languagepageData = data;
          });
        }
      });
  }

  toggle(paragraph: any, value: boolean): void {
    paragraph.showParagraph = value;
  }
}
