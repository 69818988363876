import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PressComponent } from "./views/top-menu-views/press/press.component";
import { HomeComponent } from "./views/home/home.component";
import { ContactUsComponent } from "./views/top-menu-views/contact-us/contact-us.component";
import { DeclarationsComponent } from "./views/declarations/declarations.component";
import { MembersComponent } from "./views/top-menu-views/about-us-views/members/members.component";
import { MeetingDatesComponent } from "./views/top-menu-views/about-us-views/meeting-dates/meeting-dates.component";
import { LanguageSummaryPageComponent } from "./views/top-menu-views/language-summary-page/language-summary-page.component";
import { RegulationsContainerComponent } from "./views/top-menu-views/regulations-views/regulations-container/regulations-container.component";
import { SearchComponent } from "./views/search/search.component";
import { NewsAllComponent } from "./shared/components/newsall/newsall.component";

import { VedtakComponent } from "./views/vedtak/vedtak.component";
import { SubscriptionComponent } from "./views/subscription/subscription.component";
import { CampaignComponent } from "./views/campaigns/campaign/campaign.component";
import { CampaignPregnantComponent } from "./views/campaigns/campaign_pregnant/campaign_pregnant.component";
import { CampaignSexualHarassmentComponent } from "./views/campaigns/campaign_sexual_harassment/campaign_sexual_harassment.component";
import { BasePageComponent } from "./shared/components/base-page/base-page.component";
import { AnnualReportsComponent } from "./views/top-menu-views/about-us-views/annual-reports/annual-reports.component";
import { ForTheComplaintComponent } from "./views/home-boxmenu-views/for-the-complaint/for-the-complaint.component";
import { ForTheComplaintsComponent } from "./views/home-boxmenu-views/for-the-complaints/for-the-complaints.component";
import { PressPicturesComponent } from "./views/top-menu-views/press/presspictures/presspictures.component";
import { ArticleComponent } from "./shared/components/article/article.component";
import { ComplaintsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaints.component";
import { SearchStatisticsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/search-statistics/search-statistics.component";
import { SearchComplaintsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/search-complaints/search-complaints.component";
import { ComplaintsAndStatisticsContainerComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/container/complaints-and-statistics-container.component";
import { FaqComponent } from "./views/home-boxmenu-views/faq/faq.component";
import { ProceedingsContainerComponent } from "./views/home-boxmenu-views/proceedings/proceedings-container/proceedings-container.component";
import { ComplaintReasonsComponent } from "./views/home-boxmenu-views/complaint-reasons-views/complaint-reasons.component";
import { ProceedingsComponent } from "./views/home-boxmenu-views/proceedings/proceedings/proceedings.component";
import { ComplaintDetailsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaint-details/complaint-details.component";
import { ComplaintDetailsHtmlComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaint-details-html/complaint-details-html.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "hjem", component: HomeComponent },

  {
    path: "om-oss/nemndas-medlemmer",
    component: MembersComponent,
    data: { nodeName: "om-oss/nemndas-medlemmer", nodeType: "ansatt" },
  },
  {
    path: "om-oss/sekretariatet-til-nemnda",
    component: BasePageComponent,
    data: { nodeName: "om-oss/sekretariatet-til-nemnda", twoColumns: true },
  },
  { path: "organisasjonskart", redirectTo: "om-oss/organisasjonskart" },
  {
    path: "om-oss/organisasjonskart",
    component: BasePageComponent,
    data: { nodeName: "om-oss/organisasjonskart" },
  },
  {
    path: "møtedatoer-for-nemnda",
    redirectTo: "om-oss/moetedatoer-for-nemnda",
  },
  {
    path: "om-oss/moetedatoer-for-nemnda",
    component: MeetingDatesComponent,
    data: {
      nodeName: "om-oss/moetedatoer-for-nemnda",
      // nodeType: "moetedato",
      twoColumns: true,
    },
  },
  {
    path: "årsrapporter",
    redirectTo: "om-oss/tildelingsbrev-og-aarsrapporter",
  },
  {
    path: "om-oss/tildelingsbrev-og-aarsrapporter",
    component: AnnualReportsComponent,
    data: {
      // nodeType: "arsrapporter",
      nodeName: "om-oss/tildelingsbrev-og-aarsrapporter",
      twoColumns: true,
    },
  },
  {
    path: "ledige-stillinger",
    redirectTo: "om-oss/ledige-stillinger",
  },
  {
    path: "om-oss/ledige-stillinger",
    component: BasePageComponent,
    data: { nodeName: "om-oss/ledige-stillinger", twoColumns: true },
  },
  {
    path: "verneombud",
    redirectTo: "om-oss/vaart-personvernombud",
  },
  {
    path: "om-oss/vaart-personvernombud",
    component: BasePageComponent,
    data: { nodeName: "om-oss/vaart-personvernombud", twoColumns: true },
  },
  {
    path: "diskrimineringsnemndas-virksomhet",
    redirectTo: "om-oss/diskrimineringsnemndas-virksomhet",
  },
  {
    path: "om-oss/diskrimineringsnemndas-virksomhet",
    component: BasePageComponent,
    data: {
      nodeName: "om-oss/diskrimineringsnemndas-virksomhet",
      twoColumns: true,
    },
  },

  {
    path: "regelverk",
    component: RegulationsContainerComponent,
    children: [
      { path: "om", redirectTo: "om-regelverket" },
      {
        path: "om-regelverket",
        component: BasePageComponent,
        data: { nodeName: "/regelverk/om-regelverket/", twoColumns: true },
      },
      { path: "lovdata", redirectTo: "lover-og-forskrifter" },
      {
        path: "lover-og-forskrifter",
        component: BasePageComponent,
        data: { nodeName: "regelverk/lover-og-forskrifter", twoColumns: true },
      },
      { path: "våre-høringer", redirectTo: "vaare-hoeringsuttalelser" },
      {
        path: "vaare-hoeringsuttalelser",
        component: BasePageComponent,
        data: {
          nodeName: "regelverk/vaare-hoeringsuttalelser",
          twoColumns: true,
        },
      },
      { path: "**", redirectTo: "om" },
    ],
  },

  {
    path: "presse",
    component: PressComponent,
    data: {
      nodeType: "presse",
      nodeName: "Presse",
      twoColumns: true,
      showProcessingTime: true,
    },
  },
  {
    path: "kontakt-oss",
    component: ContactUsComponent,
    data: { nodeName: "/kontakt-oss/", twoColumns: true },
  },

  /* home boxmenu components */
  { path: "for-klager", redirectTo: "informasjon-til-klager" },
  {
    path: "informasjon-til-klager",
    component: ForTheComplaintsComponent,
    data: {
      nodeName: "informasjon-til-klager",
      twoColumns: true,
      showProcessingTime: false,
    },
  },
  { path: "for-innklaget", redirectTo: "informasjon-til-innklaget" },
  {
    path: "informasjon-til-innklaget",
    component: ForTheComplaintComponent,
    data: {
      nodeName: "informasjon-til-innklaget",
      twoColumns: true,
      showProcessingTime: false,
    },
  },
  {
    path: "klagegrunnlag",
    component: ComplaintReasonsComponent,
  },
  {
    path: "klagegrunnlag/diskriminering",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/diskriminering/" },
  },
  {
    path: "klagegrunnlag/trakassering",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/trakassering/" },
  },
  {
    path: "klagegrunnlag/gjengjeldelse",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/gjengjeldelse/" },
  },
  {
    path: "klagegrunnlag/lovlig-forskjellsbehandling",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/lovlig-forskjellsbehandling/" },
  },
  {
    path: "klagegrunnlag/seksuell-trakassering",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/seksuell-trakassering/" },
  },
  {
    path: "klagegrunnlag/gjengjeldelse-etter-varsling",
    component: BasePageComponent,
    data: { nodeName: "klagegrunnlag/gjengjeldelse-etter-varsling" },
  },
  {
    path: "klagegrunnlag/tilrettelegging",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/tilrettelegging/" },
  },
  {
    path: "klagegrunnlag/universell-utforming",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/universell-utforming/" },
  },
  {
    path: "klagegrunnlag/oppreisning-og-erstatning",
    component: BasePageComponent,
    data: { nodeName: "/klagegrunnlag/oppreisning-og-erstatning/" },
  },
  { path: "saksgangen", redirectTo: "saksgangen/om-saksgangen" },
  {
    path: "muntlige-forhandlinger",
    redirectTo: "saksgangen/muntlige-forhandlinger",
  },
  { path: "vitner", redirectTo: "saksgangen/vitner" },
  {
    path: "saksgangen",
    component: ProceedingsContainerComponent,
    children: [
      {
        path: "",
        component: ProceedingsComponent,
        data: {
          nodeName: "saksgangen/om-saksgangen",
          twoColumns: true,
          showProcessingTime: true,
        },
      },
      {
        path: "muntlige-forhandlinger",
        component: ProceedingsComponent,
        data: {
          nodeName: "saksgangen/muntlige-forhandlinger",
          twoColumns: true,
          showProcessingTime: true,
        },
      },
      {
        path: "vitner",
        component: ProceedingsComponent,
        data: {
          nodeName: "saksgangen/vitner",
          twoColumns: true,
          showProcessingTime: true,
        },
      },
      // { path: '**', redirectTo: 'om-saksgangen' }
    ],
  },
  {
    path: "ofte-stilte-sporsmal",
    component: FaqComponent,
    data: { nodeName: "ofte-stilte-sporsmal" },
  },
  {
    path: "klagesaker-og-statistikk",
    component: ComplaintsAndStatisticsContainerComponent,
    children: [
      {
        path: "",
        component: SearchComplaintsComponent,
        data: { nodeName: "klagesaker-og-statistikk/soek-i-klagesaker" },
      },
      {
        path: "soek-i-statistikk",
        component: SearchStatisticsComponent,
        data: { nodeName: "klagesaker-og-statistikk/soek-i-statistikk" },
      },
      {
        path: "soek-i-gamle-klagesaker",
        component: ComplaintsComponent,
        data: {
          nodeName: "klagesaker-og-statistikk/soek-i-gamle-klagesaker",
          twoColumns: true,
          showProcessingTime: true,
        },
      },
      // { path: '**', redirectTo: 'søkklagesaker' }
    ],
  },
  {
    path: "grunnleggende-elementer/erklaeringer/personvernerklaering",
    component: DeclarationsComponent,
    data: {
      nodeName: "grunnleggende-elementer/erklaeringer/personvernerklaering",
    },
  },
  {
    path: "grunnleggende-elementer/erklaeringer/oversikt-over-informasjonskapsler",
    component: DeclarationsComponent,
    data: {
      nodeName:
        "grunnleggende-elementer/erklaeringer/oversikt-over-informasjonskapsler",
    },
  },
  {
    path: "abonnement",
    component: SubscriptionComponent,
    data: { nodeName: "Abonnement", twoColumns: true },
  },
  { path: "abonnement/:id", component: SubscriptionComponent },
  { path: "språk/:id", component: LanguageSummaryPageComponent },
  {
    path: "presse/pressebilder",
    component: PressPicturesComponent,
    data: { nodeName: "presse/pressebilder" },
  },

  { path: "sok", component: SearchComponent },

  { path: "nyheter", component: NewsAllComponent },
  { path: "nyheter/:id", component: ArticleComponent },

  { path: "showcase/:id", component: ComplaintDetailsComponent },
  { path: "showhtml/:id", component: ComplaintDetailsHtmlComponent },
  { path: "showoldcase/:id", component: VedtakComponent },
  { path: "kampanje", component: CampaignComponent },
  { path: "kampanje_gravid", component: CampaignPregnantComponent },
  {
    path: "kampanje_seksuell_trakassering",
    component: CampaignSexualHarassmentComponent,
  },
  { path: "**", redirectTo: "hjem" },
];

// function generateRoute(url: string) {
//   const basePath = "klagegrunnlag/";
//   const fullPath = basePath + url;

//   return {
//     path: `klagegrunnlag/:${url}`,
//     component: BasePageComponent,
//     data: { nodeName: fullPath },
//   };
// }

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
