import { DataService } from "src/app/shared/services/data.service";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../base/base.component";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
})
export class NewsComponent extends BaseComponent {
  newsData: any[];

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService
  ) {
    super(meta, title, router, dataService, null);
  }

  ngOnInit() {
    this.dataService.loadNews();
    this.dataService.listData.subscribe((data: any[]) => {
      this.newsData = data;
    });
  }

  goToArticle(path: string) {
    this.router.navigate([path]);
  }
}
