import { Meta, Title } from "@angular/platform-browser";
import { OnInit, OnDestroy, Injectable, Directive } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreadCrumbService } from "../../services/bread-crumb.service";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";

@Injectable()
@Directive()
export abstract class LanguageComponent implements OnInit, OnDestroy {
  protected componentDestroyed = new Subject();
  public data: any;
  public listData: any;
  protected openFirstParagraph: boolean;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService
  ) {
    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        if (data != null) {
          this.data = data;

          if (!this.data.tekst) {
            this.openFirstParagraph = true;
          }

          if (this.breadcrumbService !== null) {
            this.breadcrumbService.refresh(data.Name);
          }

          if (data.spraak) {
            let language: string = this.dataService.getLanguage(data.spraak);
            document
              .querySelector("html")
              ?.setAttribute("lang", language.toLowerCase());
          } else if (data.kode) {
            document
              .querySelector("html")
              ?.setAttribute("lang", data.kode.toLowerCase());
          }

          if (data.metaTitle) {
            this.title.setTitle(data.metaTitle);
            this.meta.updateTag({ name: "title", content: data.metaTitle });
            this.meta.updateTag({
              name: "description",
              content: data.metaDescription,
            });
            this.meta.updateTag({
              name: "keywords",
              content: data.metaKeywords,
            });
          }
        }
      });

    this.dataService.listData
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((listData: any) => {
        if (listData != null) {
          this.listData = listData;

          if (this.breadcrumbService !== null) {
            this.breadcrumbService.refresh(listData.Name);

            this.title.setTitle(listData.Name);
            this.meta.updateTag({ name: "title", content: listData.Name });
            this.meta.updateTag({
              name: "description",
              content: listData.Name,
            });
            this.meta.updateTag({ name: "keywords", content: listData.Name });
          }
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
