import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { DarkModeService } from "src/app/shared/services/dark-mode.service";
import { VFactService } from "src/app/shared/services/vfact.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SearchService } from "src/app/shared/services/search.service";
import { DataService } from "src/app/shared/services/data.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  providers: [DataService],
})
export class HeaderComponent extends BaseComponent {
  headerData: any;

  @ViewChild("navbarToggler") navbarToggler: ElementRef;
  isActive = false;

  isMobile = false;
  searchForm: UntypedFormGroup;
  isReading$: Observable<boolean>;
  darkMode = false;
  skipLinkPath = "";
  isPopoverOpen = false;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    private darkModeService: DarkModeService,
    private renderer: Renderer2,
    private vFactService: VFactService,
    private searchService: SearchService,
    private fb: UntypedFormBuilder
  ) {
    super(meta, title, router, dataService, null);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((x) => {
        this.collapseNav();
        this.vFactService.stop();
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (!this.router.url.endsWith("#main-content")) {
          this.skipLinkPath = `${this.router.url}#mainContent`;
        }
      });

    this.onResize();

    this.searchForm = this.fb.group({
      SearchTerm: [""],
    });

    this.isReading$ = this.vFactService.isReading;
  }

  ngOnInit(): void {
    this.dataService.loadHeader();
    this.dataService.listData.subscribe((data: any[]) => {
      this.headerData = data;
    });
    document.addEventListener("keydown", (e) => this.handleKeyDown(e));
  }

  toggleDarkmode() {
    if (this.darkMode) {
      this.renderer.removeClass(document.body, "dark-mode");
    } else {
      this.renderer.addClass(document.body, "dark-mode");
    }
    this.darkMode = !this.darkMode;
    this.darkModeService.set(this.darkMode);
  }

  navBarTogglerIsVisible() {
    return (
      this.navbarToggler &&
      this.navbarToggler.nativeElement.offsetParent !== null
    );
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible() && this.isActive) {
      this.navbarToggler.nativeElement.click();
    }
  }

  search() {
    if (this.searchForm.controls.SearchTerm.value !== "") {
      const val = this.searchForm.controls.SearchTerm.value;

      this.searchService.searchTerm = val;

      if (this.router.url === "/sok") {
        this.searchService.search();
      } else {
        this.router.navigateByUrl("/sok");
      }
    }
  }

  listen() {
    this.vFactService.playSection("mainContent");
  }

  stopListen() {
    this.vFactService.stop();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.collapseNav();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.isMobile = window.innerWidth < 768;

    if (event && event.target.innerWidth > 900 && this.isActive) {
      this.collapseNav();
    }
  }

  toggleSizePopover() {
    if (this.isPopoverOpen) {
      this.isPopoverOpen = false;
    } else {
      this.isPopoverOpen = true;
    }
  }
}
