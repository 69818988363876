import { Meta, Title } from '@angular/platform-browser';
import { Component, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DataService } from 'src/app/shared/services/data.service';
import { Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html'
})
export class CampaignComponent extends BaseComponent {

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private renderer: Renderer2) {
    super(meta, title, router, dataService, breadcrumbService);
    this.renderer.addClass(document.body, 'campaign');
  }

  ngOnInit(): void {
    this.dataService.loadNode('kampanje', 'Kampanje');
  }

}
