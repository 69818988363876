import { Component } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  providers: [DataService],
})
export class LanguagesComponent extends BaseComponent {
  languageData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService
  ) {
    super(meta, title, router, dataService, null);
  }

  ngOnInit(): void {
    this.dataService.loadLanguages();
    this.dataService.listData.subscribe((data: any[]) => {
      this.languageData = data;
    });
  }

  cleanName(name: string): string {
    return (
      name
        .replace(/[()]/g, "")
        // .replace(/[-]/g, "")
        .replace(/[中文]/g, "")
    );
  }
}
