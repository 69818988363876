import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { Meta, Title } from "@angular/platform-browser";
import { VedtakService } from "src/app/shared/services/vedtak.service";
import * as moment from "moment";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-vedtak",
  templateUrl: "./vedtak.component.html",
  styles: [],
  providers: [DataService],
})
export class VedtakComponent extends BaseComponent {
  private articleId: number;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute,
    private vedtakService: VedtakService,
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.vedtakService.singleResult
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data) => {
        if (data != null) {
          this.data = data;
          this.title.setTitle(`Sak #${data.nodeName} | Diskrimineringsnemnda.no`);
        }
      });

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params) => {
        if (params["id"]) {
          this.articleId = params["id"];

          // Load
          this.vedtakService.loadSingleResult(this.articleId);
        }
      });
  }

  getMomentDate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).format("LL");
    }
  }
}
