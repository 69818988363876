import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { BasePageComponent } from "src/app/shared/components/base-page/base-page.component";
import { RelatedLinkModel } from "src/app/models/related-link.model";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-for-the-complaints",
  templateUrl: "./for-the-complaints.component.html",
})
export class ForTheComplaintsComponent extends BasePageComponent {
  relatedLinks: RelatedLinkModel[];

  ngOnInit(): void {
    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        if (data.relaterteLenker && data.relaterteLenker !== "") {
          this.relatedLinks = JSON.parse(data.relaterteLenker);
        }
      });
  }
}
