import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
    name: 'AddClass'
})
export class AddClassPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }
    transform(html: any, tagSelector: any, styleValue: any): SafeHtml {
        let indexPosition = html.indexOf(`<${tagSelector}`);
        
        if (indexPosition > -1) {
            indexPosition += 1 + tagSelector.length;
            const style = ` class="${styleValue}"`;
            const newHtml = [html.slice(0, indexPosition), style, html.slice(indexPosition)].join('');
            html = newHtml;   
        }
        
        return this.sanitizer.bypassSecurityTrustHtml(html);    

    }

}