import { DataService } from "src/app/shared/services/data.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment.prod";
import { MediaService } from "src/app/shared/services/media.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-press",
  templateUrl: "./press.component.html",
  providers: [DataService, MediaService],
})
export class PressComponent extends BaseComponent {}
