import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { Meta, Title } from "@angular/platform-browser";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-article",
  templateUrl: "./article.component.html",
})
export class ArticleComponent extends BaseComponent {
  private path: string;
  newsData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute,
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params) => {
        if (params["id"]) {
          this.path = params["id"];
          this.dataService.loadNodeByPath("nyheter/" + this.path);
          this.dataService.listData.subscribe((data: any[]) => {
            this.newsData = data;
            this.title.setTitle(`${this.newsData.name} | Nyheter | Diskrimineringsnemnda.no`);
          });

          // this.data$.pipe(takeUntil(this.componentDestroyed)).subscribe(data => {
          //   if (data != null) {
          //     this.title.setTitle(data.tittel);
          //   }
          // });
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
