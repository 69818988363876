import { Component, OnInit, ViewChild } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';

import * as moment from 'moment';
import 'moment/locale/nb';

import { CookieConsentComponent } from './core/components/cookieconsent/cookie-consent.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  @ViewChild(CookieConsentComponent) cookieConsentComponent: CookieConsentComponent;

  // private documentTypeAlias: string = 'cookie';
  // private nodeName: string = 'Cookie';

  constructor(angulartics: Angulartics2GoogleGlobalSiteTag) {

    angulartics.startTracking();

  }

  ngOnInit(): void {
    moment.locale('nb');
  }

}
