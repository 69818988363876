import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download-link-cell-renderer',
  template: `
    <div class="text-left" tabindex="0">
      <i class="fa {{params.icon}}" aria-hidden="true"></i>&nbsp;
      <a tabindex="-1" target="_blank" [routerLink]="'/' + params.route + '/' + params.id">{{params.name}}</a>
    </div>`
})
export class CaseCellRendererComponent {

  params;

  constructor(private router: Router) {
  }

  agInit(params): void {
    this.params = params;
  }

}

export type CellAction = (params) => void;
