import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-proceedings',
  templateUrl: './proceedings.component.html',
  styles: [
  ]
})
export class ProceedingsComponent extends BaseComponent {
 
  
}
