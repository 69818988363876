import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { UmbracoService } from "src/app/shared/services/umbraco.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class VedtakService {
  groups: Observable<any>;
  results: Observable<any>;
  singleResult: Observable<any>;

  private _results: BehaviorSubject<any>;
  private _groups: BehaviorSubject<any>;
  private _singleResult: BehaviorSubject<any>;

  private dataStore: {
    groups: any;
    results: any;
    singleResult: any;
  };

  public selectedYear: string;
  public searchTerm: string = "";

  constructor(
    private umbracoService: UmbracoService,
    private httpClient: HttpClient
  ) {
    this.dataStore = { groups: null, results: null, singleResult: null };

    this._results = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.results = this._results.asObservable();

    this._groups = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.groups = this._groups.asObservable();

    this._singleResult = <BehaviorSubject<any[]>>new BehaviorSubject(null);
    this.singleResult = this._singleResult.asObservable();
  }

  loadGroups() {
    this.httpClient
      .get(environment.umbracoEndpoint + "/api/vedtak/GetGroups")
      .subscribe((data: any) => {
        this.setData(data);
      });
  }

  loadSingleResult(id: number) {
    this.httpClient
      .get<any>(environment.umbracoEndpoint + "/api/vedtak/GetSingle/" + id)
      .subscribe((data: any) => {
        this.setSingleResult(data);
      });
  }

  search() {
    this._results.next(null);
    const query = { Year: this.selectedYear, SearchTerm: this.searchTerm };

    if (!this.selectedYear) {
      query.Year = "0";
    }

    return this.httpClient.post<any[]>(
      environment.umbracoEndpoint + "/api/vedtak/search",
      query
    );
  }

  private setResults(data: any) {
    this.dataStore.results = data;
    this._results.next(data);
  }

  private setData(data: any) {
    this.dataStore.groups = data;
    this._groups.next(Object.assign({}, this.dataStore).groups);
  }

  private setSingleResult(data: any) {
    this.dataStore.singleResult = data;
    this._singleResult.next(Object.assign({}, this.dataStore).singleResult);
  }
}
