import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UmbracoService {
  constructor(private httpClient: HttpClient) {}

  public getNode(documentTypeAlias: string, name: string) {
    if (!name) {
      return null;
    }
    const arr = name.split(" ");
    let filterString = "";

    arr.forEach((word, index) => {
      const prefix = index > 0 ? "&filter=name%3A" : "filter=name%3A";
      filterString += prefix + encodeURIComponent(word);
    });

    const apiRequest = `${environment.umbracoEndpoint}/delivery/api/v2/content?filter=contentType%3A${documentTypeAlias}&${filterString}&skip=0&take=100&fields=properties%5B%24all%5D`;

    console.log("environment", environment.umbracoEndpoint);

    return this.httpClient.get<any>(apiRequest);
  }

  public getNodeById(id: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      environment.umbracoEndpoint + `/delivery/api/v2/content/item/${id}`,
    );
  }

  public getNodePath(path: string) {
    if (!path) {
      return null;
    }
    path = path.replace(/%20/g, "-");
    const apiRequest = `${environment.umbracoEndpoint}/delivery/api/v2/content/item/${path}`;
    return this.httpClient.get<any>(apiRequest);
  }

  public getList(
    documentTypeAlias: string,
    take: number = 0,
    orderByField?: string
  ) {
    return this.httpClient.get<any>(
      `${environment.umbracoEndpoint}/delivery/api/v2/content?fetch=children%3A${documentTypeAlias}&skip=0&take=${take}&fields=properties%5B%24all%5D${orderByField ? `&sort=${orderByField}` : '' }`,
    );
  }

  public getNewsList(
    documentTypeAlias: string,
    take: number = 0,
    // orderByField?: string
  ) {
    return this.httpClient.get<any>(
      `${environment.umbracoEndpoint}/delivery/api/v2/content?fetch=children%3A${documentTypeAlias}&sort=sortOrder%3Adesc&skip=0&take=${take}&fields=properties%5B%24all%5D`,
    );
  }

  public getMenuBoxList(
    documentTypeAlias: string,
    take: number = 0,
    // orderByField?: string
  ) {
    return this.httpClient.get<any>(
      `${environment.umbracoEndpoint}/delivery/api/v2/content?fetch=children%3A${documentTypeAlias}&sort=sortOrder%3Aasc&skip=0&take=${take}&fields=properties%5B%24all%5D`,
    );
  }

  //"/api/media/GetDocument/v2/content?fetch=children%3A146190df-4a2c-45cd-b4d5-81abd5102836&skip=0&take=10&fields=properties%5B%24all%5D"

  public getMediaFolder(id: string | number): Observable<any[]> {
    return this.httpClient.get<any[]>(
      environment.umbracoEndpoint + "/api/media/GetDocument/" + id,
    );
  }
}
