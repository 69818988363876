import { Component, OnInit, Renderer2 } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-declarations',
  templateUrl: './declarations.component.html',
  providers: [DataService]
})
export class DeclarationsComponent extends BaseComponent implements OnInit {

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService) {
    super(meta, title, router, dataService, breadcrumbService);

  }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (data: any) => {
          if (data != null && data.tekst !== '') {

            let html = data.tekst;
            let indexPosition = html.indexOf(`<table`);

            if (indexPosition > -1) {
              indexPosition += 6;
              const style = ` class="table table-bordered"`;
              const newHtml = [html.slice(0, indexPosition), style, html.slice(indexPosition)].join('');
              data.tekst = newHtml;              
            }
          }
        });

  }

}