import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  public darkMode: Observable<boolean>;
  private _darkMode: BehaviorSubject<boolean>;

  constructor() {
    this._darkMode = new BehaviorSubject<boolean>(false);
    this.darkMode = this._darkMode.asObservable();
  }

  set(val: boolean) {
    this._darkMode.next(val);
  }

}

