import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-declaration-list",
  templateUrl: "./declaration-list.component.html",
})
export class DeclarationListComponent extends BaseComponent {
  declarationData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService
  ) {
    super(meta, title, router, dataService, null);
  }

  ngOnInit(): void {
    this.dataService.loadDeclarationData();
    this.dataService.declarationData.subscribe((data: any[]) => {
      this.declarationData = data;
    });
  }
}
