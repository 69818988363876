import { Injectable } from "@angular/core";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { CookieList } from "src/app/models/cookie.model";
import { UmbracoService } from "src/app/shared/services/umbraco.service";

@Injectable({ providedIn: "root" })
export class DataService {
  public data: Observable<any>;
  private _data: Subject<any>;

  public additionalData: Observable<any>;
  private _additionalData: Subject<any>;

  public listData: Observable<any>;
  private _listData: Subject<any>;

  public declarationData: Observable<any>;
  private _declarationData: Subject<any>;

  public leaderData: Observable<any>;
  private _leaderData: Subject<any>;

  public memberData: Observable<any>;
  private _memberData: Subject<any>;

  public cookieList: Observable<any>;
  private _cookieList: Subject<any>;

  constructor(private umbracoService: UmbracoService) {
    this._data = <Subject<any>>new Subject();
    this.data = this._data.asObservable();

    this._additionalData = <Subject<any>>new Subject();
    this.additionalData = this._additionalData.asObservable();

    this._listData = <Subject<any>>new Subject();
    this.listData = this._listData.asObservable();

    this._declarationData = <Subject<any>>new Subject();
    this.declarationData = this._declarationData.asObservable();

    this._leaderData = <Subject<any>>new Subject();
    this.leaderData = this._declarationData.asObservable();

    this._memberData = <Subject<any>>new Subject();
    this.memberData = this._declarationData.asObservable();

    this._cookieList = <Subject<any>>new Subject();
    this.cookieList = this._cookieList.asObservable();
  }

  loadNode(documentTypeAlias: string, name?: string) {
    console.log("loadNode", name);
    this.umbracoService.getNodePath(name).subscribe((nodeData) => {
      forkJoin({
        getNodeResult: of(nodeData),
        getListResult: this.umbracoService.getList(name, 100),
      }).subscribe((results) => {
        const data = {
          getNodeResult: results.getNodeResult,
          getListResult: results.getListResult.items,
        };
        console.log("loadNode1234124", data);
        this._data.next(data);
      });
    });
  }

  loadLanguages() {
    this.umbracoService
      .getList("2ee04595-497e-400c-a22a-f2a4c85807df", 100)
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadHeader() {
    this.umbracoService
      .getNodeById("245244b0-81bb-4182-90c7-ac4b9affa328")
      .subscribe((data) => {
        this._listData.next(data);
      });
  }

  loadFooter() {
    this.umbracoService
      .getNodeById("0f04824f-35db-481e-a201-925aad2e9ba4")
      .subscribe((data) => {
        this._listData.next(data);
      });
  }

  loadWelcome() {
    this.umbracoService
      .getNodeById("a6626947-397c-4aca-987e-06d510aa8a5d")
      .subscribe((data) => {
        this._listData.next(data);
      });
  }

  loadMenuBoxes(id: string) {
    this.umbracoService.getMenuBoxList(id, 100).subscribe((data) => {
      this._listData.next(data.items);
    });
  }

  loadNews() {
    this.umbracoService
      .getNewsList("146190df-4a2c-45cd-b4d5-81abd5102836", 3)
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadNewsAll() {
    this.umbracoService
      .getNewsList("146190df-4a2c-45cd-b4d5-81abd5102836", 1000)
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadAllMembers() {
    forkJoin({
      leaders: this.umbracoService.getList(
        "fd628720-b774-44f7-81b0-1730106755bc",
        100,
      ),
      members: this.umbracoService.getList(
        "759b6036-89b4-4159-98af-22a42967b0b7",
        100,
      ),
      deputies: this.umbracoService.getList(
        "80b262a6-2fa2-4ae1-a130-dfad73a7844f",
        100,
      ),
    }).subscribe((results) => {
      const data = {
        getLeaders: results.leaders.items,
        getMembers: results.members.items,
        getDeputies: results.deputies.items,
      };
      console.log("loadNodeMembers", data);
      this._listData.next(data);
    });
  }
  // loadMembers() {
  //   this.umbracoService
  //     .getList("759b6036-89b4-4159-98af-22a42967b0b7", 100)
  //     .subscribe((data) => {
  //       this._memberData.next(data.items);
  //       console.log("loadMembers", data.items);
  //     });
  // }

  // loadDeputies() {
  //   this.umbracoService
  //     .getList("80b262a6-2fa2-4ae1-a130-dfad73a7844f", 100)
  //     .subscribe((data) => {
  //       this._listData.next(data.items);
  //       console.log("loadDeputies", data.items);
  //     });
  // }

  loadMeetingDates() {
    this.umbracoService
      .getList("2f4c5428-03d0-41b3-9b17-e68415eb6461", 100, "sortOrder:asc")
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadAnnualreportsInfo() {
    this.umbracoService
      .getNodeById("96e75696-238b-4228-934c-c444d717aa1c")
      .subscribe((data) => {
        this._listData.next(data);
      });
  }

  loadAnnualreports() {
    this.umbracoService
      .getList("96e75696-238b-4228-934c-c444d717aa1c", 100, 'sortOrder:desc')
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadFaqData() {
    this.umbracoService
      .getList("f7ae981f-a770-49f3-b267-4b93485e7b4b", 100)
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadIssueInfo() {
    this.umbracoService
      .getList("fe4c8373-8f5e-4d90-a45c-df1405cbe558", 100)
      .subscribe((data) => {
        this._listData.next(data.items);
      });
  }

  loadDeclarationData() {
    this.umbracoService.getList("erklaeringer", 100).subscribe((data) => {
      this._declarationData.next(data.items);
    });
  }

  loadNodeById(id: string) {
    this.umbracoService.getNodeById(id).subscribe((data) => {
      this._listData.next(data);
    });
  }

  loadNodeByPath(path: string) {
    this.umbracoService.getNodePath(path).subscribe((data) => {
      this._listData.next(data);
    });
  }

  loadNodeList(documentTypeAlias: string, take?: number) {
    this.umbracoService.getList(documentTypeAlias, take).subscribe((data) => {
      this._listData.next(data.items);
      console.log("loadNodeList", data.items);
    });
  }

  loadCookieList() {
    this.umbracoService
      .getList("b890333c-6902-4820-985d-6013ac8d9a26", 10)
      .subscribe((data: CookieList) => {
        this._cookieList.next(data.items);
      });
  }

  getLanguage(language: string) {
    if (!language) {
      return "no";
    } else {
      if (language.toLowerCase() === "bokmål") {
        return "no";
      } else if (language.toLowerCase() === "nynorsk") {
        return "nn";
      }
    }
  }
}
