import { Component, HostListener } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Observable } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ComplaintsService,
  SearchFilterModel,
  SearchFilter,
} from "src/app/shared/services/complaints.service";
import { CaseCellRendererComponent } from "src/app/shared/renderers/casecell.renderer.component";
import {
  GridOptions,
  SuppressHeaderKeyboardEventParams,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import { DateCellRendererComponent } from "src/app/shared/renderers/datecell.renderer.component";
import { DataCellRendererComponent } from "src/app/shared/renderers/datacell.renderer.component";
import { BreadcrumbsComponent } from "src/app/shared/components/breadcrumbs/breadcrumbs.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-search-complaints",
  templateUrl: "./search-complaints.component.html",
  styles: [],
  providers: [DataService],
})
export class SearchComplaintsComponent extends BaseComponent {
  scrHeight: any;
  scrWidth: any;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 768) {
      this.showSearchFilter = false;
    } else {
      this.showSearchFilter = true;
    }
  }

  months = moment.months();

  //https://www.ag-grid.com/angular-data-grid/keyboard-navigation/#example-tabbing-into-the-grid
  //https://www.ag-grid.com/javascript-data-grid/keyboard-navigation/#tabbing-into-the-grid
  //https://plnkr.co/edit/?open=app%2Fapp.component.ts&preview
  //https://www.ag-grid.com/angular-data-grid/keyboard-navigation/#example-suppress-keys

  gridOptions: GridOptions = {
    context: { parentComponent: this },
    suppressRowClickSelection: true,
    // suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
    //   return true;
    // },
    // suppressHeaderKeyboardEvent: (params: SuppressHeaderKeyboardEventParams) => {
    //   return false;
    // }

    // suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
    //   const e = params.event;
    //   if (e.code == 'Tab' || e.key == 'Tab') {
    //     //get focusable children of parent cell
    //     let focusableChildrenOfParent = e.srcElement.closest(".ag-cell")
    //       .querySelectorAll('button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])');

    //     if (focusableChildrenOfParent.length == 0 ||
    //       (e.shiftKey == false && e.srcElement == focusableChildrenOfParent[focusableChildrenOfParent.length - 1]) ||
    //       (e.shiftKey == true && e.srcElement == focusableChildrenOfParent[0]) ||
    //       (e.shiftKey == true && e.srcElement.classList.contains("ag-cell")))
    //       return false; //do not suppress
    //     return true; //suppress
    //   }
    //   return false; //do not suppress by default
    // }
  };

  columnDefs = [
    {
      headerName: "Dato",
      field: "dato",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 130,
      // suppressHeaderKeyboardEvent: (params) => {
      //   return true;
      // },
      cellRenderer: DateCellRendererComponent,
    },
    {
      headerName: "Saksnummer",
      field: "arkivsakIdFriendlyName",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 120,
      // suppressHeaderKeyboardEvent: (params) => {
      //   return true;
      // },
      cellRenderer: CaseCellRendererComponent,
      cellRendererParams: (params) => {
        return {
          icon: "fa-link",
          name: params.data.arkivsakIdFriendlyName,
          id: params.data.arkivsakId,
          route: "showcase",
        };
      },
    },
    {
      headerName: "Grunnlag",
      field: "grunnlagSpesialGrunnlag",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 230,
      cellRenderer: DataCellRendererComponent,
      valueGetter: (params) => {
        return params.data.grunnlagSpesialGrunnlag.map(
          (elem: { data: any }) => {
            return elem.data;
          }
        );
      },
    },
    {
      headerName: "Samfunnsområde!",
      field: "samfunnsomrade",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 230,
    },
    {
      headerName: "Status",
      field: "status",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 230,
    },
    {
      headerName: "Resultat",
      field: "resultat",
      headerClass: "agCell",
      cellClass: "agCell",
      sortable: true,
      width: 150,
      cellRenderer: DataCellRendererComponent,
      valueGetter: (params) => {
        return params.data.resultat.map((elem) => {
          return elem.data;
        });
      },
    },
  ];

  searching$: Observable<boolean>;
  config$: Observable<any>;
  results$: Observable<any[]>;

  searchFilterModel: SearchFilterModel;

  showSearchFilter = true;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private complaintsService: ComplaintsService
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.getScreenSize();

    this.searching$ = this.complaintsService.searhing;
    this.results$ = this.complaintsService.results;
    this.config$ = this.complaintsService.config;

    this.complaintsService.loadConfig();
    this.resetFilter();
  }

  onDoubleClick(row) {
    row.context.parentComponent.openCase(row.data.arkivsakId);
  }

  openCase(CaseId: string) {
    this.router.navigateByUrl("/showcase/" + CaseId);
  }

  search() {
    const SearchFilters = this.searchFilterModel.SearchFilters.filter(
      (value: SearchFilter) => value.SearchFilter !== "Alle"
    );

    if (
      this.searchFilterModel.SearchTerm !== "" ||
      SearchFilters !== undefined
    ) {
      const filter: SearchFilterModel = {
        SearchFilters: SearchFilters,
        SearchTerm: this.searchFilterModel.SearchTerm,
      };
      this.complaintsService.search(filter);
    }
  }

  getDropdownValue(colId: number) {
    const val = this.searchFilterModel.SearchFilters.find((elem) => {
      return elem.ColId === colId;
    });

    return val ? val.SearchFilter : "";
  }

  setDropdownValue(colId: number, value: string) {
    const index = this.searchFilterModel.SearchFilters.findIndex((elem) => {
      return elem.ColId === colId;
    });

    if (index > -1) {
      // set correct month number
      if (colId === 16 && value !== "Alle") {
        const month = moment().month(value).format("M");
        this.searchFilterModel.SearchFilters[index] = {
          ColId: colId,
          SearchFilter: month,
        };
      } else {
        this.searchFilterModel.SearchFilters[index] = {
          ColId: colId,
          SearchFilter: value,
        };
      }

      // reset dropdown for month is all years is selected
      if (colId === 15 && value === "Alle") {
        const mIndex = this.searchFilterModel.SearchFilters.findIndex(
          (elem) => {
            return elem.ColId === 16;
          }
        );
        if (mIndex > -1) {
          this.searchFilterModel.SearchFilters[mIndex] = {
            ColId: 16,
            SearchFilter: value,
          };
        }
      }
    }
  }

  getMonthName(monthVal) {
    if (monthVal === "Alle") {
      return monthVal;
    } else {
      return moment.months(monthVal - 1);
    }
  }

  toggleSearchFilter() {
    this.showSearchFilter = !this.showSearchFilter;
  }

  resetFilter() {
    this.searchFilterModel = {
      SearchTerm: "",
      SearchFilters: [
        {
          ColId: 15, // År
          SearchFilter: "Alle",
        },
        {
          ColId: 16, // Måned (eg DatoMottatt)
          SearchFilter: "Alle",
        },
        {
          ColId: 14, // Status
          SearchFilter: "Alle",
        },
        {
          ColId: 1, // Bruddmåte
          SearchFilter: "Alle",
        },
        {
          ColId: 2, // Grunnlag
          SearchFilter: "Alle",
        },
        {
          ColId: 3, // Spesialgrunnlag
          SearchFilter: "Alle",
        },
        {
          ColId: 4, // Samfunnsområde
          SearchFilter: "Alle",
        },
        {
          ColId: 5, // Kjønn
          SearchFilter: "Alle",
        },
        {
          ColId: 6, // Hasteavgjørelse
          SearchFilter: "Alle",
        },
        {
          ColId: 7, // Muntlig behandling
          SearchFilter: "Alle",
        },
        {
          ColId: 8, // Forsterket nemnd
          SearchFilter: "Alle",
        },
        {
          ColId: 11, // Spesielle forhold
          SearchFilter: "Alle",
        },
        {
          ColId: 9, // Resultat
          SearchFilter: "Alle",
        },
        {
          ColId: 10, // Konsekvens brudd
          SearchFilter: "Alle",
        },
      ],
    };
  }

  getMomentDate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).format("L");
    }
  }

  export(results: any[]) {
    this.complaintsService.exportToExcel(results);
  }
}
