import { Component } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Observable, BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControlDirective,
  FormControlName,
} from "@angular/forms";
import { ValidationService } from "src/app/shared/services/validation.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";
import { MessageModel } from "src/app/models/message.model";

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);

  if (this.control) {
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  }

  return result;
};

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styles: [],
  providers: [DataService],
})
export class ContactUsComponent extends BaseComponent {
  lat: number = 60.389314;
  lng: number = 5.334276;

  contactForm: UntypedFormGroup;
  submitting = false;

  messageSent = new BehaviorSubject<boolean>(false);

  mapStyle: any[] = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#46bcec",
        },
        {
          visibility: "on",
        },
      ],
    },
  ];

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private fb: UntypedFormBuilder,
    private http: HttpClient
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.createForm();
  }

  sendMessage() {
    if (!this.contactForm.valid) {
      this.checkControls(this.contactForm);
      return;
    }

    this.submitting = true;

    const inputData: MessageModel = {
      Name: this.contactForm.value.Name,
      Email: this.contactForm.value.Email,
      Message: this.contactForm.value.Message,
      NorwegianNN: this.contactForm.value.NorwegianNN,
    };

    this.http
      .post<Boolean>(environment.umbracoEndpoint + "/api/mail/send", inputData)
      .subscribe((result) => {
        this.messageSent.next(true);
        this.submitting = false;
        this.createForm();

        setTimeout(() => {
          this.messageSent.next(false);
        }, 5000);
      });
  }

  createForm() {
    this.contactForm = this.fb.group({
      Name: ["", Validators.required],
      Email: ["", [Validators.required, ValidationService.emailValidator]],
      Message: ["", [Validators.required, Validators.minLength(10)]],
      NorwegianNN: false,
    });
  }

  checkControls(group: UntypedFormGroup) {
    for (const fieldName in group.controls) {
      const control = group.get(fieldName);

      if (control instanceof UntypedFormGroup) {
        if (this.checkControls(control)) {
          break;
        }
      } else {
        if (control.invalid && !control.dirty) {
          control.markAsDirty();
          if (control) {
            (<any>control).nativeElement.focus();
          }
          return true;
        }
      }
    }
  }
}
