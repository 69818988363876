// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Locally running Backend
// export const environment = {
//   production: false,
//   endpoint: 'https://localhost:4200',
//   umbracoEndpoint: 'http://localhost:4319/umbraco',
// };

// Staging Backend running on server
export const environment = {
  production: false,
  endpoint: "https://www-ver.diskrimineringsnemnda.no",
  umbracoEndpoint: "https://www-ver.diskrimineringsnemnda.no/umbraco",
};

// Production Backend running on server
// export const environment = {
//   production: false,
//   endpoint: "https://www.diskrimineringsnemnda.no",
//   umbracoEndpoint: "https://www.diskrimineringsnemnda.no/umbraco",
// };
