import { Injectable } from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AdvenioCrumb } from "../components/advenio-breadcrumbs/advenio-breadcrumbs.component";

@Injectable({
  providedIn: "root",
})
export class BreadCrumbService {
  public breadCrumbData: Observable<AdvenioCrumb[]>;
  private _breadCrumbData: BehaviorSubject<AdvenioCrumb[]>;

  private defaultHomeBreadcrumb: AdvenioCrumb = {
    title: "Diskrimineringsnemnda",
    url: "/",
  };

  constructor(private router: Router, private titlecasePipe: TitleCasePipe) {
    this._breadCrumbData = new BehaviorSubject<AdvenioCrumb[]>([
      this.defaultHomeBreadcrumb,
    ]);
    this.breadCrumbData = this._breadCrumbData.asObservable();
  }

  public refresh(name: string): void {
    let breadcrumbData: AdvenioCrumb[] = [];
    breadcrumbData.push(this.defaultHomeBreadcrumb);

    let url: string = decodeURI(this.router.url);
    let elements: string[] = url
      .split("/")
      .filter((r) => r !== "" && r !== "hjem" && r !== "erklæringer");

    elements.forEach((element: string, index: number) => {
      let title: string = this.titlecasePipe.transform(element);
      // if (index === elements.length - 1) {
      //   title = name;
      // }

      let breadcrumbElement: AdvenioCrumb = {
        title: decodeURI(title),
        url: decodeURI(element),
      };

      breadcrumbData.push(breadcrumbElement);
    });

    this._breadCrumbData.next(breadcrumbData);
  }
}
