import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advenio-image',
  templateUrl: './advenio-image.component.html',
  styles: []
})
export class AdvenioImageComponent implements OnInit {

  @Input() imageData: string[] | string;
  @Input() description = '';
  @Input() alt = '';
  @Input() maxHeight = 'auto';

  public innerWidth: any;

  constructor() { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  imageDataIsArray(): boolean {
    if (this.imageData && Array.isArray(this.imageData)) {
      return true;
    }
  }

  @HostListener('window.resize', ['$event'])
  onresize(event) {
    this.innerWidth = window.innerWidth;
  }

}