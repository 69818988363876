import { DataService } from 'src/app/shared/services/data.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'src/app/shared/services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';

@Component({
  selector: 'app-presspictures',
  templateUrl: './presspictures.component.html',
  providers: [DataService, MediaService]
})
export class PressPicturesComponent extends BaseComponent {

  images$: Observable<any>;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private mediaService: MediaService) {
      super(meta, title, router, dataService, breadcrumbService);

    this.images$ = this.mediaService.data;
    this.mediaService.load(1148); // 1148

  }

}
