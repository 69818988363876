import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-members",
  templateUrl: "./members.component.html",
  providers: [DataService],
})
export class MembersComponent extends BaseComponent {
  protected nodeName: string;
  memberData: any;

  ngOnInit() {
    this.dataService.loadAllMembers();
    this.dataService.listData.subscribe((data: any[]) => {
      this.memberData = data;
    });
  }
}
