import { Meta, Title } from '@angular/platform-browser';
import { Component, Renderer2 } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DataService } from 'src/app/shared/services/data.service';
import { Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';


@Component({
  selector: 'app-pregnant-campaign',
  templateUrl: './campaign_pregnant.component.html'
})
export class CampaignPregnantComponent extends BaseComponent {

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private renderer: Renderer2) {
    super(meta, title, router, dataService, breadcrumbService);
    this.renderer.addClass(document.body, 'campaign');
  }

  ngOnInit(): void {
    this.dataService.loadNode('campaign', 'Kampanje_gravid');
  }

}
