import { Component } from "@angular/core";
import { Router } from "@angular/router";

import * as moment from "moment";

@Component({
  selector: "app-download-link-cell-renderer",
  template: ` <div tabindex="0">
    {{ date
    }}<span
      >&nbsp;&nbsp;<span
        container="body"
        placement="right"
        [tooltip]="
          data.datoAvsluttet === null ? 'Mottatt dato' : 'Avgjort dato'
        "
        class="circle"
        [ngClass]="{ finished: data.datoAvsluttet === null }"
      ></span
    ></span>
  </div>`,
})
export class DateCellRendererComponent {
  data: any;
  date: string;

  constructor(private router: Router) {}

  agInit(params): void {
    this.data = params.data;
    const date = params.value;

    if (date !== null && date !== undefined) {
      this.date = moment(date).format("L");
    }
  }
}

export type CellAction = (params) => void;
