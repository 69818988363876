import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download-link-cell-renderer',
  template: `
    <div tabindex="0" container="body" placement="right" [tooltip]="concatData">
      {{concatData}}
    </div>`
})
export class DataCellRendererComponent {

  data: any[];
  concatData: string;

  constructor() {
      this.concatData = '';
  }

  agInit(params): void {

    this.data = params.value;
    if (this.data.length > 0) {
        this.concatData = this.data.map(
            (elem) => {
                return elem;
            }
        ).join(', ');    
    } 
  }
}

export type CellAction = (params) => void;
