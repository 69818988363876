import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UmbracoService } from 'src/app/shared/services/umbraco.service';

@Injectable()
export class MediaService {

    data: Observable<any>;
    private _data: BehaviorSubject<any>;
    private dataStore: {
        data: any
    };

    constructor(private umbracoService: UmbracoService, private httpClient: HttpClient) {
        this.dataStore = { data: null };
        this._data = <BehaviorSubject<any[]>>new BehaviorSubject(null);
        this.data = this._data.asObservable();
    }

    load(documentTypeId: string | number) {
        this.umbracoService.getMediaFolder(documentTypeId).subscribe(data => {
            this.setData(data);
        });
    }

    private setData(data: any) {
        this.dataStore.data = data;
        this._data.next(Object.assign({}, this.dataStore).data);
    }

}
