import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-regulations-container',
  templateUrl: './regulations-container.component.html',
})
export class RegulationsContainerComponent extends BaseComponent {




}
