import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";
import { Meta, Title } from "@angular/platform-browser";
import { LanguageComponent } from "src/app/shared/components/base/language.component";

@Component({
  selector: "app-language-summary-page",
  templateUrl: "./language-summary-page.component.html",
})
export class LanguageSummaryPageComponent extends LanguageComponent {
  private id;
  languagepageData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        if (params.id) {
          this.id = params.id;
          this.dataService.loadNodeById(this.id);
          this.dataService.listData.subscribe((data: any[]) => {
            this.languagepageData = data;
          });
        } else {
          this.router.navigateByUrl("/hjem");
        }
      });
  }
}
