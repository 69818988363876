import { Component } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Observable } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import {
  StatisticsService,
  StatisticsSearchFilterModel,
} from "src/app/shared/services/statistics.service";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-search-statistics",
  templateUrl: "./search-statistics.component.html",
  styles: [],
  providers: [DataService],
})
export class SearchStatisticsComponent extends BaseComponent {
  months = moment.months();

  initStats$: Observable<any>;
  results$: Observable<any>;
  searching$: Observable<boolean>;

  today = moment();

  statFilter: StatisticsSearchFilterModel;

  openById = {};

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private statisticsService: StatisticsService
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    this.searching$ = this.statisticsService.searhing;
    this.initStats$ = this.statisticsService.initStats;
    this.statisticsService.loadInitStats();
    this.results$ = this.statisticsService.results;

    this.resetFilter(true);
  }

  ngOnInit(): void {
    // this.dataService.loadNode('complaintsAndStatistics', 'søk i statistikk');
  }

  panelChange(id) {
    let value: boolean = this.openById[id];

    this.openById[id] = !value;
  }

  searchWithFilter() {
    this.statisticsService.searchWithFilter(this.statFilter);
  }

  // searchData() {
  //   this.statisticsService.staticSearch();
  // }

  setYearValue(value) {
    if (value === "Alle") {
      this.statFilter.Year = -1;
    } else {
      this.statFilter.Year = value;
    }
  }

  getYearValue() {
    if (this.statFilter !== undefined) {
      if (this.statFilter.Year === -1) {
        return "Alle";
      } else {
        return this.statFilter.Year;
      }
    }
  }

  getMonthValue() {
    if (this.statFilter !== undefined) {
      if (this.statFilter.Month === -1) {
        return "Alle";
      } else {
        return moment.months(this.statFilter.Month);
      }
    }
  }

  setMonthValue(value) {
    if (value === "Alle") {
      this.statFilter.Month = -1;
    } else {
      const month = moment().month(value).format("M");
      this.statFilter.Month = parseInt(month, 10) - 1;
    }
  }

  resetFilter(doSearch: boolean) {
    this.statFilter = {
      Month: -1, // moment().month(), // zero indexed
      Year: moment().year(),
    };

    if (doSearch) {
      this.searchWithFilter();
      // this.searchData();
    }
  }

  getMomentDate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).format("L");
    }
  }
}
