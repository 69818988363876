import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CookieConsent } from "src/app/models/cookie.consent.model";
import { CookieList, CookieListItem } from "src/app/models/cookie.model";
import { CookieService } from "src/app/shared/services/cookie.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-cookie-consent",
  templateUrl: "./cookie-consent.component.html",
  styleUrls: ["./cookie-consent.component.scss"],
  animations: [
    trigger("toggleBox", [
      // ...
      state(
        "open",
        style({
          transform: "transform3d(0,0,0)",
        }),
      ),
      state(
        "closed",
        style({
          transform: "translate3d(-100%, 0, 0)",
          visibility: "hidden",
        }),
      ),
      transition("open => closed", [animate(".4s")]),
      transition("closed => open", [animate(".4s")]),
    ]),
  ],
  providers: [DataService],
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  private componentDestroyed = new Subject();
  cookieConsentForm!: UntypedFormGroup;
  isOpen: boolean = false;

  cookie: CookieConsent;
  cookieList: CookieList;

  constructor(
    private dataService: DataService,
    private cookieService: CookieService,
    private builder: UntypedFormBuilder,
  ) {
    this.cookieService.cookie
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((c: CookieConsent) => {
        if (!c) {
          this.cookie = {
            isRead: false,
          };
          if (this.cookieList.items != undefined) {
            this.cookieList.items.forEach(
              (element: CookieListItem, index: number) => {
                if (
                  element.properties &&
                  typeof element.properties.script === "string" &&
                  index > 1 &&
                  index < this.cookieList.items.length - 1
                ) {
                  this.cookie[
                    (element.properties.script as string).toLowerCase()
                  ] = false;
                }
              },
            );
          }
        } else {
          this.cookie = c;
        }

        this.createForm();
      });

    this.dataService.cookieList
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((cookieList: CookieList) => {
        this.cookieList = cookieList;
        this.cookieService.loadCookie();
      });

    this.dataService.loadCookieList();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() {
    this.cookieService.consentVisible
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((val: boolean) => {
        this.isOpen = val;
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkConsent();
    }, 500);
  }

  public checkConsent() {
    if (!this.cookie || this.cookie.isRead === false) {
      this.cookieService.showCookieConsentDialog();
    } else {
      this.cookieService.loadScripts();
    }
  }

  createForm() {
    if (!this.cookieConsentForm) {
      this.cookieConsentForm = this.builder.group(this.cookie);
    }
  }

  saveCookieConsent() {
    this.cookieConsentForm.controls.isRead.setValue(true);

    let consent: CookieConsent = this.cookieConsentForm.value;
    this.cookieService.saveCookieConsent(consent);
    this.cookieService.hideCookieConsentDialog();
  }

  saveAllCookieConsent() {
    Object.keys(this.cookieConsentForm.controls).forEach((key) => {
      this.cookieConsentForm.controls[key].setValue(true);
    });

    this.saveCookieConsent();
  }
}
