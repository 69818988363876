import { Component } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { RelatedLinkModel } from "src/app/models/related-link.model";
import { BaseComponent } from "src/app/shared/components/base/base.component";

@Component({
  selector: "app-for-the-complaint",
  templateUrl: "./for-the-complaint.component.html",
})
export class ForTheComplaintComponent extends BaseComponent {
  relatedLinks: RelatedLinkModel[];

  ngOnInit(): void {
    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        if (
          data.getNodeResult.properties.relaterteLenker &&
          data.getNodeResult.properties.relaterteLenker !== ""
        ) {
          this.relatedLinks = JSON.parse(
            data.getNodeResult.properties.relaterteLenker
          );
        }
      });
  }
}
