import { DataService } from "src/app/shared/services/data.service";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base/base.component";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { BreadCrumbService } from "../../services/bread-crumb.service";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  providers: [DataService],
})
export class WelcomeComponent extends BaseComponent {
  welcomeData: any;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService
  ) {
    super(meta, title, router, dataService, breadcrumbService);
  }

  ngOnInit(): void {
    this.dataService.loadWelcome();
    this.dataService.listData.subscribe((data: any[]) => {
      this.welcomeData = data;
      this.title.setTitle("Forside | Diskrimineringsnemnda.no");
    });
  }
}
