import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from 'src/app/shared/services/data.service';
import { DarkModeService } from 'src/app/shared/services/dark-mode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-proceedings-container',
  templateUrl: './proceedings-container.component.html'
})
export class ProceedingsContainerComponent extends BaseComponent {



}
