import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { ComplaintsService, ArkivsakModel, ArkivsakFile } from 'src/app/shared/services/complaints.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DataService } from 'src/app/shared/services/data.service';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';

@Component({
  selector: 'app-complaint-details',
  templateUrl: './complaint-details.component.html',
  styles: [],
  providers: []
})
export class ComplaintDetailsComponent extends BaseComponent {

  arkivSakId: string;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute,
    private complaintsService: ComplaintsService) {
    super(meta, title, router, dataService, breadcrumbService);

    this.complaintsService.complaint
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        if (data != null) {
          this.data = data;
          this.title.setTitle(`Sak #${data.arkivsakIdFriendlyName.toString()} | Diskrimineringsnemnda.no`);
        }
      });

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {
        if (params['id']) {

          this.arkivSakId = params['id'];

          // Load complaint
          this.complaintsService.loadComplaint(this.arkivSakId);
        }
      });
  }

  gotoHtml(complaint: ArkivsakModel, file: ArkivsakFile) {
    this.router.navigate(['/showhtml/' + complaint.arkivsakId], { state: { name: file.name, url: file.url } });
  }

  getMomentDate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).format('L');
    }
  }

}
