import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule, TitleCasePipe } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { CoreModule } from "./core/core.module";
import { UmbracoService } from "./shared/services/umbraco.service";
import { BreadcrumbsComponent } from "./shared/components/breadcrumbs/breadcrumbs.component";
import { NgPipesModule } from "ngx-pipes";
import { ContactUsComponent } from "./views/top-menu-views/contact-us/contact-us.component";
import { DeclarationsComponent } from "./views/declarations/declarations.component";
import { HomeComponent } from "./views/home/home.component";
import { PressComponent } from "./views/top-menu-views/press/press.component";
import { MomentModule } from "ngx-moment";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BoxMenuComponent } from "./shared/components/box-menu/box-menu.component";
import { WelcomeComponent } from "./shared/components/welcome/welcome.component";
import { NewsComponent } from "./shared/components/news/news.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { SortableModule } from "ngx-bootstrap/sortable";
import { GoBackComponent } from "./shared/components/go-back/go-back.component";
import { AdvenioImageComponent } from "./shared/components/advenio-image/advenio-image.component";
import { ProcessingTimeComponent } from "./shared/components/processing-time/processing-time.component";
import { AdvenioBreadcrumbsComponent } from "./shared/components/advenio-breadcrumbs/advenio-breadcrumbs.component";
import { LanguageSummaryPageComponent } from "./views/top-menu-views/language-summary-page/language-summary-page.component";
import { ValidationService } from "./shared/services/validation.service";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CollapseModule } from "ngx-bootstrap/collapse";

import { VFactService } from "./shared/services/vfact.service";
import { VedtakComponent } from "./views/vedtak/vedtak.component";
import { HighLightPipe } from "./shared/pipes/highlight.pipe";
import { SearchComponent } from "./views/search/search.component";
import { NewsAllComponent } from "./shared/components/newsall/newsall.component";

// tslint:disable-next-line: max-line-length
import { ComplaintsAndStatisticsContainerComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/container/complaints-and-statistics-container.component";
import { ComplaintsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaints.component";
import { SearchComplaintsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/search-complaints/search-complaints.component";
import { SearchStatisticsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/search-statistics/search-statistics.component";
import { VedtakService } from "./shared/services/vedtak.service";
import { ComplaintsService } from "./shared/services/complaints.service";
import { StatisticsService } from "./shared/services/statistics.service";
import { ComplaintDetailsComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaint-details/complaint-details.component";

import { AgGridModule } from "ag-grid-angular";
import { CaseCellRendererComponent } from "./shared/renderers/casecell.renderer.component";
import { DateCellRendererComponent } from "./shared/renderers/datecell.renderer.component";

import { ComplaintDetailsHtmlComponent } from "./views/home-boxmenu-views/complaints-and-statistics-views/complaints/complaint-details-html/complaint-details-html.component";
import { SafePipe } from "./shared/pipes/safe.pipe";
import { SubscriptionComponent } from "./views/subscription/subscription.component";
import { SubscriptionService } from "./shared/services/subscription.service";
import { Angulartics2Module } from "angulartics2";
import { DataCellRendererComponent } from "./shared/renderers/datacell.renderer.component";
import { CampaignVideoComponent } from "./shared/components/campaign-video/campaign-video.component";
import { CampaignComponent } from "./views/campaigns/campaign/campaign.component";
import { CampaignPregnantComponent } from "./views/campaigns/campaign_pregnant/campaign_pregnant.component";
import { CampaignSexualHarassmentComponent } from "./views/campaigns/campaign_sexual_harassment/campaign_sexual_harassment.component";
import { CookieConsentComponent } from "./core/components/cookieconsent/cookie-consent.component";
import { CookieService } from "./shared/services/cookie.service";
import { LazyLoadingScriptService } from "./shared/services/lazy-loading-script.service";
import { ComplaintReasonsComponent } from "./views/home-boxmenu-views/complaint-reasons-views/complaint-reasons.component";
import { ComplaintReasonsDisplayComponent } from "./views/home-boxmenu-views/complaint-reasons-views/complaint-reasons-display/complaint-reasons-display.component";
import { AddClassPipe } from "./shared/pipes/addclass.pipe";
import { ProceedingsContainerComponent } from "./views/home-boxmenu-views/proceedings/proceedings-container/proceedings-container.component";
import { BasePageComponent } from "./shared/components/base-page/base-page.component";
import { ArticleComponent } from "./shared/components/article/article.component";
import { FaqComponent } from "./views/home-boxmenu-views/faq/faq.component";
import { ForTheComplaintComponent } from "./views/home-boxmenu-views/for-the-complaint/for-the-complaint.component";
import { ForTheComplaintsComponent } from "./views/home-boxmenu-views/for-the-complaints/for-the-complaints.component";
import { PressPicturesComponent } from "./views/top-menu-views/press/presspictures/presspictures.component";
import { RegulationsContainerComponent } from "./views/top-menu-views/regulations-views/regulations-container/regulations-container.component";
import { ProceedingsComponent } from "./views/home-boxmenu-views/proceedings/proceedings/proceedings.component";
import { MembersComponent } from "./views/top-menu-views/about-us-views/members/members.component";
import { MeetingDatesComponent } from "./views/top-menu-views/about-us-views/meeting-dates/meeting-dates.component";
import { AnnualReportsComponent } from "./views/top-menu-views/about-us-views/annual-reports/annual-reports.component";
import { LanguagePageComponent } from "./shared/components/base-page/language-page.component";
import { SafeUrlPipe } from "./shared/pipes/safe-url.pipe";

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    BreadcrumbsComponent,
    ArticleComponent,
    VedtakComponent,
    ComplaintsAndStatisticsContainerComponent,
    ComplaintsComponent,
    SearchComplaintsComponent,
    SearchStatisticsComponent,
    ComplaintDetailsComponent,
    ComplaintDetailsHtmlComponent,
    ContactUsComponent,
    DeclarationsComponent,
    CookieConsentComponent,
    FaqComponent,
    SubscriptionComponent,
    ComplaintsComponent,
    ForTheComplaintComponent,
    MembersComponent,
    MeetingDatesComponent,
    AnnualReportsComponent,
    ForTheComplaintsComponent,
    HomeComponent,
    PressComponent,
    PressPicturesComponent,
    ProceedingsContainerComponent,
    BoxMenuComponent,
    CampaignComponent,
    CampaignPregnantComponent,
    CampaignSexualHarassmentComponent,
    CampaignVideoComponent,
    WelcomeComponent,
    NewsComponent,
    NewsAllComponent,
    GoBackComponent,
    AdvenioImageComponent,
    ProcessingTimeComponent,
    AdvenioBreadcrumbsComponent,
    LanguageSummaryPageComponent,
    RegulationsContainerComponent,
    HighLightPipe,
    SafePipe,
    AddClassPipe,
    CaseCellRendererComponent,
    DateCellRendererComponent,
    DataCellRendererComponent,
    ComplaintReasonsComponent,
    ComplaintReasonsDisplayComponent,
    ProceedingsComponent,
    BasePageComponent,
    LanguagePageComponent,
    SafeUrlPipe,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    SortableModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    Angulartics2Module.forRoot(),
    // NgcCookieConsentModule.forRoot(cookieConfig),
    CoreModule,
    NgPipesModule,
    MomentModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AgGridModule,
    // AgmCoreModule.forRoot({
    //     apiKey: 'AIzaSyDFOpXOp9wBeeWc2EfLX32Bax3JJXOOqOc'
    // }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    UmbracoService,
    VedtakService,
    ComplaintsService,
    StatisticsService,
    SubscriptionService,
    ValidationService,
    VFactService,
    CookieService,
    LazyLoadingScriptService,
    SafePipe,
    AddClassPipe,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
