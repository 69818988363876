import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-complaint-reasons-display',
  templateUrl: './complaint-reasons-display.component.html',
  styles: [],
  providers: [DataService]
})
export class ComplaintReasonsDisplayComponent extends BaseComponent {

  contentName: string;
  showFull: boolean[] = [];

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private route: ActivatedRoute) {
    super(meta, title, router, dataService, breadcrumbService);

    this.route.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (params: Params) => {
          if (params.name) {
            this.contentName = params.name;
            this.dataService.loadNode('klagegrunnlag', this.contentName);
          }
        }
      );

    this.dataService.data
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (data: any) => {
          this.setParagraphsViewState();
        }
      );
  }

  setParagraphsViewState(): void {
    this.showFull = [];
    this.data.Items?.forEach((element, index) => {
      this.showFull.push(index === 0);            
    });
  }

  toggle(index: number): void {
    this.showFull[index] = !this.showFull[index];
  }

}
