import { Component, OnDestroy, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Observable, Subject } from "rxjs";
import { ComplaintsService } from "../../services/complaints.service";
import { DarkModeService } from "../../services/dark-mode.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-processing-time",
  templateUrl: "./processing-time.component.html",
  providers: [DataService],
})
export class ProcessingTimeComponent implements OnInit, OnDestroy {
  private componentDestroyed = new Subject();

  processingTime: any;
  darkMode = false;
  processingData: any;

  constructor(
    private darkModeService: DarkModeService,
    private complaintService: ComplaintsService,
    protected dataService: DataService
  ) {
    this.darkModeService.darkMode
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((val: boolean) => {
        this.darkMode = val;
      });

    this.complaintService.processing
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((val: any) => {
        this.processingTime = val;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() {
    this.complaintService.loadProcessingTime();
  }
}
