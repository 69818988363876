import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SubscriptionService {
  subscriptionTypes: Observable<SubscriptionType[]>;
  private _subscriptionTypes: BehaviorSubject<SubscriptionType[]>;

  constructor(private httpClient: HttpClient) {
    this._subscriptionTypes = <BehaviorSubject<SubscriptionType[]>>(
      new BehaviorSubject(undefined)
    );
    this.subscriptionTypes = this._subscriptionTypes.asObservable();
  }

  loadSubscriptionTypes() {
    this.httpClient
      .get<any>(
        environment.umbracoEndpoint + "/api/Subscription/getsubscriptiontypes"
      )
      .subscribe(
        (data: SubscriptionType[]) => {
          this._subscriptionTypes.next(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
        () => {}
      );
  }

  saveSubscriptions(input: SubscriptionInput) {
    return this.httpClient.post<SubscriptionType[]>(
      environment.umbracoEndpoint + "/api/Subscription/addsubscriptions",
      input
    );
  }

  unsubscribe(id: string) {
    return this.httpClient.post<boolean>(
      environment.umbracoEndpoint + "/api/Subscription/stop/" + id,
      undefined
    );
  }
}

export interface SubscriptionInput {
  Subscriber: Subscriber;
  SubscriptionTypes: SubscriptionType[];
}

export interface Subscription {
  Id?: string;
  Subscriber: Subscriber;
  SubscriptionType: SubscriptionType;
  Created?: Date;
  Modified?: Date;
}

export interface Subscriber {
  Id?: string;
  Created?: Date;
  Modified?: Date;
  // FirstName: string;
  // LastName: string;
  Email: string;
  // Phone: string;
  Company: string;
}

export interface SubscriptionType {
  Id: string;
  Name: string;
  Created: Date;
  Modified: Date;
}
