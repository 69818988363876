import { Component } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { DataService } from "src/app/shared/services/data.service";


@Component({
  selector: "app-meeting-dates",
  templateUrl: "./meeting-dates.component.html",
  styles: [],
  providers: [DataService],
})
export class MeetingDatesComponent extends BaseComponent {
  meetingData: any;

  ngOnInit() {
    this.dataService.loadMeetingDates();
    this.dataService.listData.subscribe((data: any[]) => {
      this.meetingData = data;
    });
  }
}
