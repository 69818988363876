import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { Observable } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { DataService } from "src/app/shared/services/data.service";
import { VedtakService } from "src/app/shared/services/vedtak.service";

import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadCrumbService } from "src/app/shared/services/bread-crumb.service";

@Component({
  selector: "app-complaints",
  templateUrl: "./complaints.component.html",
  providers: [DataService],
})
export class ComplaintsComponent extends BaseComponent {
  results: any[];
  groups: any[];
  selectedGroup: any;
  searchTerm: string;

  constructor(
    public meta: Meta,
    public title: Title,
    protected router: Router,
    protected dataService: DataService,
    protected breadcrumbService: BreadCrumbService,
    private vedtakService: VedtakService
  ) {
    super(meta, title, router, dataService, breadcrumbService);

    // this.vedtakService.results
    //   .pipe(takeUntil(this.componentDestroyed))
    //   .subscribe((data: any[]) => {
    //     if (data != null) {
    //       this.results = data;

    //       if (this.searchTerm && this.searchTerm !== "") {
    //         this.applyFilter();
    //       }
    //     }
    //   });

    this.vedtakService.groups
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any) => {
        if (data != null) {
          this.groups = data.groups;

          if (
            this.vedtakService.selectedYear &&
            this.vedtakService.selectedYear !== ""
          ) {
            const groupIndex = this.groups.findIndex((item: any) => {
              return item.Key === this.vedtakService.selectedYear;
            });

            this.selectedGroup = data.Groups[groupIndex];
          }

          this.searchTerm = this.vedtakService.searchTerm;
          this.search();
        }
      });

    if (this.groups === undefined) {
      this.vedtakService.loadGroups();
    }
  }
  ngOnInit(): void {
    this.vedtakService
      .search()
      // .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: any[]) => {
        console.log("data", data);
        if (data != null) {
          this.results = data;

          if (this.searchTerm && this.searchTerm !== "") {
            this.applyFilter();
          }
        }
      });
  }

  applyFilter() {
    this.results.forEach((element) => {
      element.Ingress = element.Ingress.replace(
        this.searchTerm,
        '<span class="highlight-text">' + this.searchTerm + "</span>"
      );
    });
  }

  onGroupChange($event) {
    if (this.selectedGroup === "") {
      this.selectedGroup = undefined;
    }

    this.search();
  }

  searchManually(): void {
    if (this.searchTerm.length > 1) {
      this.search();
    }
  }

  search() {
    this.vedtakService.selectedYear = this.selectedGroup
      ? this.selectedGroup.Key
      : undefined;
    this.vedtakService.searchTerm = this.searchTerm;
    this.vedtakService.search().subscribe((data: any) => {
      this.results = data;
    });
  }

  getMomentDate(date) {
    if (date !== null && date !== undefined) {
      return moment(date).format("LL");
    }
  }
}
